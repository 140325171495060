import { CallBoxVM, ForwardVM, ProductResponseItem, SessionToken } from '@upass/callbox';
import { Component, OnInit } from '@angular/core';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { SessionService } from 'src/app/services/session.service';
import { CallboxService } from 'src/app/services/callbox.service';
import { ForwardService } from 'src/app/services/forward.service';
import moment from 'moment';

@Component({
  selector: 'app-subscription-change',
  templateUrl: './subscription-change.component.html',
  styleUrls: ['./subscription-change.component.scss']
})
export class SubscriptionChangeComponent implements OnInit {
  plans: ProductResponseItem[];
  callboxes: CallBoxVM[];
  forwards: ForwardVM[];
  selectedPlan: ProductResponseItem = null;
  isLoading = 3;
  currentPlan: ProductResponseItem = null;
  step = 0;
  isDowngrade = false;
  isOverCapacity = false;
  token: SessionToken = null;
  selectedCallboxes: CallBoxVM[] = [];
  selectedForwards: ForwardVM[] = [];
  subscriptionLastChanged: string;
  subscriptionChangeFlag: boolean;

  constructor(private subSvc: SubscriptionService, private sessionSvc: SessionService, private callboxSvc: CallboxService, private forwardSvc: ForwardService) { }

  ngOnInit(): void {
    this.subSvc.listPrices().subscribe(res => {
      this.plans = res;

      this.sessionSvc.getSession().subscribe(session => {
        const token = this.sessionSvc.decode(session);
        console.log("GET SESSION: " + token.scd)
        this.token = token;
        this.subscriptionLastChanged = token.scd;
        this.subscriptionChangeFlag = token.scd && moment(token.scd).add(48, 'hours').isAfter(moment())
        this.currentPlan = this.plans.find(p => p.productId == token.pid);
        this.isLoading--;

        console.log(this.isLoading);
      })
    });



    this.callboxSvc.listCallboxes().subscribe(res => {
      this.callboxes = res;
      this.isLoading--;
    });

    this.forwardSvc.listForwards().subscribe(res => {
      this.forwards = res;
      this.isLoading--;
    })

  }

  clickNext(plan: ProductResponseItem) {
    this.selectedPlan = plan;
    this.isDowngrade = this.selectedPlan.productId < this.currentPlan.productId;
    this.isOverCapacity = this.callboxes.length > this.selectedPlan.callboxes || this.forwards.length > this.selectedPlan.forwards;
    console.log("isDowngrade", this.isDowngrade);
    console.log("isOverCapacity", this.isOverCapacity);

    if(this.isDowngrade && this.isOverCapacity) {
      this.step = 1;
      this.selectedCallboxes = [];
      this.selectedForwards = [];
    } else {
      this.step = 2;
    }

  }

  clickedNextCapacity({ callboxes, forwards }: { callboxes: CallBoxVM[], forwards: ForwardVM[] }) {
    console.log("capacity output", callboxes, forwards);
    this.step = 2;
  }

  clickBackCapacity() {
    this.step = 0;
  }

  clickedBackConfirm() {
    this.step = 0;

    if(this.isDowngrade && this.isOverCapacity) {
      this.step = 1;
    } else {
      this.step = 0;
    }
  }

}
