import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import moment from "moment";
import { CallBoxVM } from "@upass/callbox";
import { PassService } from 'src/app/services/pass.service';
import { Router, ActivatedRoute } from "@angular/router";
import { CallboxService } from 'src/app/services/callbox.service';

@Component({
  selector: 'app-pass-create',
  templateUrl: './pass-create.component.html',
  styleUrls: ['./pass-create.component.scss']
})
export class PassCreateComponent implements OnInit {
  startDate = moment().startOf("hour")
  endDate = moment().add(1, "day").endOf("day");
  callboxId: string = null;
  isLoading = false;
  callboxes: CallBoxVM[] = null;
  errors:string[] = [];

  minDate = moment().startOf("day").toISOString().replace("Z", "");

  constructor(private passSvc: PassService, private callboxSvc: CallboxService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {

    // get locations list from user
    this.isLoading = true;
    this.callboxSvc.listCallboxes().subscribe(res => {
      this.callboxes = res;
      this.isLoading = false;
      this.callboxId = res[0].callboxId;
    });

  }

  submit() {
    const now = moment().startOf("day");
    this.errors = [];

    if(this.startDate.isBefore(now) || this.endDate.isBefore(now)) {
      //pass must be in future
      this.errors.push("Pass dates must be in the future");
    }

    if(this.endDate.isBefore(this.startDate)) {
      //enddate must come after start dater
      this.errors.push("End date cannot be before start date");
    }

    if(this.errors.length > 0) {
      return;
    }

    let params = {
      startDt: this.startDate.toISOString(),
      endDt: this.endDate.toISOString(),
      callboxId: this.callboxId
    }

    this.isLoading = true;
    //post data
    this.passSvc.createPass(params).subscribe(res => {
      this.router.navigate(["../", res.shareId], { relativeTo: this.route });
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      console.log(err);
    })
  }
}
