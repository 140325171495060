import { Component, EventEmitter, Input, OnInit, Output  } from '@angular/core';
import {  Location  } from '@angular/common';
import { ActivatedRoute, OutletContext, Router } from '@angular/router';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent implements OnInit {

  @Input() useBrowserBack = false;
  @Input() preventDefault = false;
  @Output() clicked = new EventEmitter();

  constructor(private location: Location, private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
  }

  clickBack(event: any): void {
    this.clicked.next(event);

    if(this.preventDefault){
      return;
    }

    if(this.useBrowserBack) {
      this.location.back();
    } else {
      this.router.navigate(['../'], {
        relativeTo: this.activatedRoute
      });
    }
  }
}
