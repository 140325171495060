import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ForwardPutParams, ForwardVM } from '@upass/callbox';
import { getCountriesFmt } from 'src/app/common/country-codes.models';
import { ForwardService } from 'src/app/services/forward.service';
import parsePhoneNumber, { isPossiblePhoneNumber, isValidPhoneNumber, parse } from 'libphonenumber-js'
import { parsePhone, validatePhone } from 'src/app/common/phone-validation';


@Component({
  selector: 'app-forward-edit',
  templateUrl: './forward-edit.component.html',
  styleUrls: ['./forward-edit.component.scss'],
})
export class ForwardEditComponent implements OnInit {
  isSubmitted = false;
  isLoading = true;
  model: ForwardPutParams;
  phone: string;
  validPhone = false;

  constructor(
    private forwardSvc: ForwardService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((param) => {
      const phone = param.get('phone');
      const state = this.location.getState() as ForwardVM;


      console.log(state);
      if (state.forwardId != null) {
        this.model = {
          forwardNumber: state.forwardNumber,
          priorityOrder: state.priorityOrder,
          forwardId: state.forwardId,
        };
        this.phone = state.forwardNumber;

        this.isLoading = false;
        return;
      } else {
        //get forward
        this.forwardSvc.getForward(phone).subscribe({
          next: (res) => {
            this.model = {
              forwardNumber: res.forwardNumber,
              priorityOrder: res.priorityOrder,
              forwardId: res.forwardId,
            };
            this.phone = res.forwardNumber;
            this.isLoading = false;
          },
          error: () => {
            alert('Error loading forward.');
          },
          complete: () => {
            this.isLoading = false;
          },
        });
      }
    });
  }

  submit(form: NgForm) {
    this.isSubmitted = true;

    if (!form.valid) {
      return;
    }


    if(!this.validPhone) {
      return;
    }

    //save forward
    this.model.forwardNumber = this.phone;
    this.isLoading = true;
    this.forwardSvc.putForward(this.model).subscribe({
      next: (res) => {
        this.isLoading = false;
        this.router.navigate(['../'], { relativeTo: this.route });
      },
      error: () => {
        this.isLoading = false;
        alert('Error saving forward');
      },
    });
  }

  delete() {
    if(confirm("Are you sure you want to delete this forward?")) {
      this.isLoading = true;
      this.forwardSvc.deleteForwards(this.model.forwardId).subscribe({
        next: res => {
          this.router.navigate(['../'], { relativeTo: this.route });
        },
        error: err => {
          alert("Error deleting forward");
          this.isLoading = true;
        }
      });
    }
  }

  isValidPhone(value: boolean) {
    this.validPhone = value;
  }
}
