<div class="fade-in">

  <app-page-header title="Update Phone"></app-page-header>

  <div class="page" *ngIf="!isLoading">
    <div>
      <div class="d-flex justify-content-center">
        <img src="/assets/img/bell-icon.svg" class="header-img grow-in" />
      </div>
      <p class="text-muted text-center" *ngIf="!codeSent">Update your phone number.</p>
    </div>

    <!--body-->

    <div *ngIf="!codeSent">
      <form #form="ngForm" name="callbox" (submit)="clickSendCode()">
        <div>
          <app-phone-input (isValidPhone)="isValidPhone($event)" name="phone" phoneSubText="" [(value)]="phone" [showErrors]="isSubmitted"></app-phone-input>
        </div>

        <div class="text-danger text-sm" *ngIf="isSubmitted && form.form.invalid">
          Please fill out all fields
        </div>


        <p class="text-muted text-center"> You'll receive a 6 digit code by text</p>

        <div class="d-flex flex-column mt-3 justify-content-center">
          <button class="btn btn-primary" (click)="clickSendCode()">
            Send Code
          </button>
        </div>
      </form>

    </div>




    <div *ngIf="codeSent">

      <p class="text-muted text-center" *ngIf="!validated">Code is sent to {{phone | phoneFormat}}</p>

      <!-- Before validated-->
      <div class="row justify-content-center mb-3" *ngIf="!validated">
        <div class="col-8">
          <label>Verification Code</label>
          <div class="verification-input d-flex">
            <input type="string" class="form-control flex-grow-1" [(ngModel)]="code"/>
            <button class="btn btn-primary" (click)="clickValidate()" >
              Verify
            </button>
          </div>
          <div *ngIf="validationFailed">
            <span class="text-danger">Invalid code</span>
          </div>
        </div>
      </div>

      <!-- after validated-->
      <div class="row justify-content-center mb-3"  *ngIf="validated">
        <div class="col-8 d-flex flex-column align-items-center justify-content-center">
          <label>Phone Verified</label>
          <div>
            <div class="uphone-container">{{phone | phoneFormat}}</div>
            <h5 class="fa-solid fa-check text-success text-large"></h5>
          </div>
        </div>
      </div>



      <div class="d-flex flex-column justify-content-center align-items-center mb-3">
        <div *ngIf="!validated">
          Didn't receive code? <a class="btn btn-link p-0" (click)="sendCode()">Send again</a>
        </div>
        <div>
        <a *ngIf="!validated" class="btn btn-link p-0" (click)="changePhone()">Change Phone number</a>
        </div>
        <div>
          <a *ngIf="validated" class="btn btn-link p-0" [routerLink]="['../']">Done</a>
        </div>
      </div>
    </div>
  </div>



  <div class="page" *ngIf="isLoading">
    <div class="d-flex justify-content-center">
      <app-page-loader></app-page-loader>
    </div>
  </div>
</div>
