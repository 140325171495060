<div>

  <app-page-header title="Billing History"></app-page-header>

  <div class="page mb-3" *ngIf="!isLoadingPlan">
    <div class="text-primary mb-2">YOUR SUBSCRIPTION</div>
    <div class="text-sm text-muted">Plan</div>
    <div>uPass Callbox {{product.name}}</div>
    <div class="mb-3">${{product.price / 100 | number : '1.2-2'}}/month</div>
    <div>
      <div class="text-sm text-sm text-muted">Next billing date</div>
      <div *ngIf="nextBillingDate">{{nextBillingDate | date}}</div>
    </div>
  </div>

  <div class="page">

    <div *ngIf="!isLoadingInvoices">
      <div *ngFor="let n of invoices" class="list-row" (click)="clickInvoice(n)">
          <div class="row mb-2">
            <div class="col text-bold text-primary">
              {{n.date | date }}
            </div>
            <div class="col-auto">
              ${{n.total / 100 | number : '1.2-2'}}
            </div>
          </div>
          <div class="mx-2 mb-2 text-sm">
            uPass Callbox {{n.description}}
            <br />
            {{n.periodStart  | date }} - {{n.periodEnd  | date }}
          </div>
          <div class="mx-2 text-sm">
            <div *ngIf="n.cardNumberLast4 != null">
              <span>
                <i class="fa-brands fa-cc-visa" *ngIf="n.cardType === 'visa'"></i>
                <i class="fa-brands fa-cc-mastercard" *ngIf="n.cardType === 'mastercard'"></i>
                <i class="fa-brands fa-cc-stripe" *ngIf="n.cardType === 'stripe'"></i>
                <i class="fa-brands fa-cc-discover" *ngIf="n.cardType === 'discover'"></i>
                <i class="fa-brands fa-cc-paypal" *ngIf="n.cardType === 'paypal'"></i>
                <i class="fa-brands fa-cc-jcp" *ngIf="n.cardType === 'jcp'"></i>
                <i class="fa-brands fa-cc-apple-pay" *ngIf="n.cardType === 'apple-pay'"></i>
                <i class="fa-brands fa-cc-amazon-pay" *ngIf="n.cardType === 'amazon-pay'"></i>
                <i class="fa-brands fa-cc-amex" *ngIf="n.cardType === 'amex'"></i>
              </span>
               &#x2022;&#x2022;&#x2022;&#x2022; &#x2022;&#x2022;&#x2022;&#x2022; &#x2022;&#x2022;&#x2022;&#x2022; {{n.cardNumberLast4}}
            </div>
            <div>
              ${{n.subtotal / 100 | number : '1.2-2'}} <span class="text-muted">(+ ${{n.taxTotal / 100 | number : '1.2-2' }} tax)</span>
            </div>
            <div *ngIf="n.refundTotal" class="text-success">
              Refunded: +${{n.refundTotal / 100 | number : '1.2-2'}}
            </div>
          </div>
      </div>
      <div class="text-sm text-muted mt-3">
        Only the last 12 months of history is displayed. For prior invoices, contact support.
      </div>
    </div>

    <div *ngIf="isLoadingInvoices" class="d-flex justify-content-center">
      <upass-spinner></upass-spinner>
    </div>

  </div>
</div>
