import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-setup-step-buttons',
  templateUrl: './setup-step-buttons.component.html',
  styleUrls: ['./setup-step-buttons.component.scss']
})
export class SetupStepButtonsComponent implements OnInit {
  @Output() next =  new EventEmitter<any>();
  @Output() previous =  new EventEmitter<any>();
  @Output() done =  new EventEmitter<any>();
  @Input() showDone = false;
  @Input() showPrevious = true;

  constructor() { }

  ngOnInit(): void {
  }

}
