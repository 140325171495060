import { CallBoxVM, ForwardVM, ProductResponseItem, SessionToken } from '@upass/callbox';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-subscription-change-select',
  templateUrl: './subscription-change-select.component.html',
  styleUrls: ['./subscription-change-select.component.scss']
})
export class SubscriptionChangeSelectComponent implements OnInit {
  @Input() selectedPlan: ProductResponseItem;
  @Input() plans: ProductResponseItem[];
  @Input() currentPlan: ProductResponseItem;
  @Input() subscriptionLastChanged: string;
  @Input() subscriptionChangeFlag: boolean;
  @Output() onSelectedPlan = new EventEmitter<ProductResponseItem>();
  newPlan: ProductResponseItem;

  constructor() { }

  ngOnInit(): void {
  }

  clickPlan(plan: ProductResponseItem) {
    this.newPlan = plan;
  }


  clickNext() {
    if(this.newPlan == this.currentPlan) {
      return;
    }

    this.onSelectedPlan.emit(this.newPlan);
  }
}
