

<div *ngIf="!isLoading">
  <div>
    <h3 class="text-center text-thin my-5">
      Choose a plan that works for you
    </h3>
  </div>
  <div class="row justify-content-center">
    <div class="col-11">


      <div class="card plan-card" *ngFor="let n of plans">
          <div>
            <span class="plan-name">{{n.name}}</span>
            <span class="badge rounded-pill bg-success float-end text-xs" *ngIf="n.freeTrial">Free Trial</span>
          </div>

          <div class="plan-header flex-grow-1 text-muted" [innerHTML]="n.header"></div>


          <!-- Icons-->
          <div class="product-tiles mt-3">
            <div class="product-tile">
              <i class="fa-solid fa-square-phone"></i>
              <span>{{n.callboxes}}</span>
              <span class="product-label">callboxes</span>
            </div>
            <div class="product-tile">
              <i class="fa-solid fa-address-book"></i>
              <span>{{n.forwards}}</span>
              <span class="product-label">forward #s</span>
            </div>
          </div>

          <div>
            <span class="plan-cost">${{n.price / 100 }}</span>
            <span class="plan-frequency">/month</span>
          </div>

          <div class="flex-grow-1" [innerHTML]="n.description"></div>

          <div class="subscribe-btn">
            <button class="btn btn-primary" (click)="clickNext(n)">Choose this plan</button>
          </div>
      </div>
    </div>

  </div>
</div>


<div class="d-flex flex-column align-items-center justify-content-center mb-5" *ngIf="isLoading">
  <h3 class="text-center text-thin my-5">
    Loading Plans...
  </h3>
  <app-large-spinner></app-large-spinner>
</div>
