<div class="">
  <div class="d-flex justify-content-center">
    <div class="img animate"></div>
  </div>
  <div class="wrapper">
     <div class="comment br animate w80"></div>
     <div class="comment br animate"></div>
     <div class="comment br animate"></div>
     <div class="comment br animate"></div>
  </div>
<div>
