
<form>
  <app-page-header title="Verify Capacity" [preventBack]="true" (clickedBack)="clickedBack()"></app-page-header>
  <div class="page">

    <div *ngIf="!isLoading">
      <div>
        <h5 class="text-center mt-3">Since your downgrading</h5>
        <div class="mb-3">
          You'll need to remove some callboxes and/or forwards for your new plan.
        </div>
      </div>

      <!-- callboxes-->
      <div class="mb-3" *ngIf="maxDeleteCallboxes > 0">
        <div>
          <h6>Callboxes ({{selectedCallboxes.length}}/{{maxDeleteCallboxes}})</h6>
          <div class="text-sm text-muted">
            Select the forwards you would like to delete.
          </div>
        </div>
        <div *ngFor="let n of callboxes" class="form-check">
          <input class="form-check-input" type="checkbox" [value]="n" id="callbox_{{n.callboxId}}" #cbInput
            [disabled]="!cbInput.checked && selectedCallboxes.length == maxDeleteCallboxes"
            [checked]="(selectedCallboxes && (-1 !== selectedCallboxes.indexOf(n)) ? 'checked' : '')" (change)="updateSelectedCallbox($event, n)">
          <label class="form-check-label" for="callbox_{{n.callboxId}}">
            {{n.name}}
          </label>
        </div>
      </div>

      <!-- forwards-->
      <div class="mb-3">
        <div>
          <h6>Forwards ({{selectedForwards.length}}/{{maxDeleteForwards}})</h6>
          <div class="text-sm text-muted">
            Select the forwards you would like to delete.
          </div>
        </div>
        <div *ngFor="let n of forwards" class="form-check">
          <input class="form-check-input" type="checkbox" [value]="n" id="callbox_{{n.forwardId}}" #fwInput
            [disabled]="!fwInput.checked && selectedForwards.length == maxDeleteForwards"
            [checked]="(selectedForwards && (-1 !== selectedForwards.indexOf(n)) ? 'checked' : '')" (change)="updateSelectedForward($event, n)">
          <label class="form-check-label" for="callbox_{{n.forwardId}}">
            {{n.forwardNumber}}
          </label>
        </div>
      </div>


      <div class="my-4">
        <i>
          The callboxes and forwards you selected will be deleted when you confirm your plan on the next page.
        </i>
      </div>

      <div>
        <button class="btn btn-primary btn-block" (click)="clickNext()" [disabled]="maxDeleteCallboxes !== selectedCallboxes.length || maxDeleteForwards !== selectedForwards.length">
          Next
        </button>
      </div>
    </div>


    <div *ngIf="isLoading">
      <app-page-loader></app-page-loader>
    </div>

  </div>
</form>
