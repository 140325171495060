import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CreateCallBox, ProductResponseItem } from '@upass/callbox';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'app-setup-subscription',
  templateUrl: './setup-subscription.component.html',
  styleUrls: ['./setup-subscription.component.scss']
})
export class SetupSubscriptionComponent implements OnInit {
  @Output() next = new EventEmitter<number>();
  @Input() model: { productId: number, itemId: string };
  isLoading = true;
  plans: ProductResponseItem[];

  constructor(private subSvc: SubscriptionService) { }

  ngOnInit(): void {
    this.subSvc.listPrices().subscribe(res => {
      this.plans = res;
      this.isLoading = false;
    });
  }

  clickNext(n: ProductResponseItem) {
    this.model.productId = n.productId;
    this.model.itemId = n.itemId;
    this.next.emit();
  }
}
