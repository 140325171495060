import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CreateCallBox } from '@upass/callbox';
import { CallboxService } from 'src/app/services/callbox.service';

@Component({
  selector: 'app-setup-location',
  templateUrl: './setup-location.component.html',
  styleUrls: ['./setup-location.component.scss']
})
export class SetupLocationComponent implements OnInit {
  @Output() next = new EventEmitter<any>();
  @Output() previous = new EventEmitter<any>();
  @Output() commited = new EventEmitter<any>();
  @Input() model: CreateCallBox;
  @ViewChild('form', { read: NgForm }) form: any;
  isSubmitted = false;
  isLoading = false;

  constructor(private callboxSvc: CallboxService) { }

  ngOnInit(): void {
  }


  clickNext() {
    console.log(this.form);

    if (!this.isSubmitted && !this.form.valid) {
      this.isSubmitted = true;
      return;
    }

    if (!this.form.valid) {
      return;
    }

    this.next.emit();





  }

  clickPrevious() {
    this.previous.emit();
  }
}
