import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CreateCallBox, SubscriptionStatus } from '@upass/callbox';
import { CallboxService } from 'src/app/services/callbox.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-setup-success',
  templateUrl: './setup-success.component.html',
  styleUrls: ['./setup-success.component.scss']
})
export class SetupSuccessComponent implements OnInit {
  @Output() next = new EventEmitter<any>();
  @Output() previous = new EventEmitter<any>();
  @Input() model: CreateCallBox;
  isLoading = true;
  phone = "";

  constructor(private router: Router, private sessionService: SessionService, private callboxSvc: CallboxService) { }

  ngOnInit(): void {

    //get session until subscription shows as subscribed
    this.sessionService.getSession(false).subscribe({
      next: (token) => {
        let decoded = this.sessionService.decode(token);
        if (decoded.sst !== SubscriptionStatus.SUBSCRIBED) {
          console.log("NOT SUBSCRIBED YET");
          return;
        }

        this.phone = decoded.upn;
        this.isLoading = false;
      },
      error: () => { console.log("Error getting session") }
    });
  }


  clickDone() {
    if (!this.isLoading) {
      this.next.emit();
    }
  }

}
