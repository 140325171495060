<div>

  <app-page-header title="My Profile"></app-page-header>

  <div class="page">
    <div *ngIf="!isLoading">

      <div class="mb-3 text-sm" *ngIf="!isNative">
        Some fields may be disabled because youre using social login
      </div>

      <div class="profile-group" (click)="navigate(['email'], false)" [ngClass]="{ 'disabled': !isNative }">
        <div class="profile-field">
          <label class="text-sm text-muted">Email Address</label>
          <div class="item-data">{{email}}</div>
        </div>
        <i class="fa-solid fa-chevron-right"></i>
      </div>

      <div class="profile-group" (click)="navigate(['name'], false)" [ngClass]="{ 'disabled': !isNative }">
        <div class="profile-field">
          <label class="text-sm text-muted">Name</label>
          <div class="item-data">{{name}}</div>
        </div>
        <i class="fa-solid fa-chevron-right"></i>
      </div>

      <div class="profile-group" (click)="navigate(['username'], false)" [ngClass]="{ 'disabled': !isNative }">
        <div class="profile-field">
          <label class="text-sm text-muted">Username</label>
          <div class="item-data">{{username}}</div>
        </div>
        <i class="fa-solid fa-chevron-right"></i>
      </div>

      <div class="profile-group" (click)="navigate(['password'], false)" *ngIf="isNative">
        <div class="profile-field">
          <label class="text-sm text-muted">Password</label>
          <div class="item-data">**********</div>
        </div>
        <i class="fa-solid fa-chevron-right"></i>
      </div>

      <div class="profile-group" [routerLink]="['phone']">
        <div class="profile-field">
          <label class="text-sm text-muted">Phone</label>
          <div class="item-data">{{phone | phoneFormat}}</div>
        </div>
        <i class="fa-solid fa-chevron-right"></i>
      </div>


      <!-- <div class="mb-3" *ngIf="phone" class="">
        <div class="text-center">Your upass phone number</div>
        <div class="d-flex justify-content-center">

        </div>
      </div> -->

    </div>
    <div *ngIf="isLoading" class="d-flex justify-content-center">
      <upass-spinner></upass-spinner>
    </div>

  </div>
</div>
