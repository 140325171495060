import { UserNotificationSettings } from '@upass/callbox';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  isLoading = false;
  options: UserNotificationSettings;

  constructor(private userSvc: UserService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.userSvc.getNotificationOptions().subscribe(res => {
      this.isLoading = false;
      this.options = res;
    })
  }

  onChange(field: string, value: boolean) {
    (this.options as any)[field] = value;

    this.putSettings();
  }


  putSettings() {

    const model = {
      enabledSMS: this.options.enabledSMS,
      enabledEmail: this.options.enabledEmail,
      smsOnEntry: this.options.smsOnEntry,
      smsOnForward: this.options.smsOnForward,
      emailOnEntry: this.options.emailOnEntry,
      emailOnForward: this.options.emailOnForward,
    }

    //commit settings
    //TODO: Possibly make this a single field update if this model gets too large
    this.userSvc.putNotificationOptions(model).subscribe({
      next: res => {

      },
      error: err => {
        alert("Error saving notificaiton settings");
      }
    });

  }
}
