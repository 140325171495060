<app-page-header title="Payment Methods"></app-page-header>
<div class="page">

  <div *ngIf="!isLoading">
    <div class="d-flex justify-content-center">
      <img src="/assets/img/payment-icon.svg" class="header-img" />
    </div>

    <div class="mb-3 text-center">
      Add a new payment method or switch your preferred payment method.
    </div>


    <div>
      <div *ngFor="let n of methods" class="list-item">
        <div>
          <app-card-number [last4]="n.cardNumberLast4" [type]="n.cardType"></app-card-number>
          <span class="float-end">Exp: {{n.expMonth}}/{{n.expYear % 100}}</span>
        </div>

          <div class="d-flex align-items-baseline my-2">
            <span class="badge bg-secondary" *ngIf="n.isPrimary">Preferred</span>
            <a *ngIf="!n.isPrimary" class="btn-link" (click)="setPreferredPayment(n.paymentMethodId)">Make Preferred</a>
            <div class="flex-grow-1"></div>
            <!-- <a class="btn-link mx-5">Edit</a> -->
            <a class="btn-link" (click)="deletePaymentMethod(n.paymentMethodId)" *ngIf="!n.isPrimary">Remove</a>
          </div>
      </div>

    </div>

    <div class="d-flex justify-content-stretch">
      <button class="btn btn-primary btn-block" (click)="clickNewPayment()">
        <i class="fa fa-plus"></i>
        New Payment Method
      </button>
    </div>

  </div>

  <div class="d-flex justify-content-center" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>
</div>
