import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  isLoading = true;
  email: string = null;
  phone: string = null;
  username: string = null;
  name: string = null;
  isNative = true;

  constructor(private sessionSvc: SessionService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.sessionSvc.getToken().subscribe(ses => {
      this.isLoading = false;
      this.email = ses.email;
      this.phone = ses.pno;
      this.username = ses.uname || "No Username";
      this.name = ses.name;
      this.isNative = ses.inu;
    })
  }


  navigate(path: string[], allowNonNative: boolean) {
    if(!allowNonNative && !this.isNative) {
      return;
    }

    this.router.navigate(path, { relativeTo: this.route });
  }
}
