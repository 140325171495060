import { CallBoxVM, ForwardVM, ProductResponseItem, SessionToken } from '@upass/callbox';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-resubscribe-select',
  templateUrl: './resubscribe-select.component.html',
  styleUrls: ['./resubscribe-select.component.scss']
})
export class ResubscribeSelectComponent implements OnInit {
  @Input() selectedPlan: ProductResponseItem;
  @Input() plans: ProductResponseItem[];
  @Output() onSelectedPlan = new EventEmitter<ProductResponseItem>();
  newPlan: ProductResponseItem;

  constructor() { }

  ngOnInit(): void {
  }


  clickPlan(n: ProductResponseItem) {
    this.newPlan = n;
  }

  clickNext() {
    this.onSelectedPlan.emit(this.newPlan);
  }
}
