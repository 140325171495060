import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

const circle_radius = 30;
const circle_stroke = 2 * Math.PI * circle_radius;

@Component({
  selector: 'app-setup-stepper',
  templateUrl: './setup-stepper.component.html',
  styleUrls: ['./setup-stepper.component.scss']
})
export class SetupStepperComponent implements OnInit, OnChanges {

  /** Total number of steps */
  @Input() steps: number;
  @Input() currentStep: number;
  @Input() stepText: string;
  @Input() stepNextText: string;

  stroke = circle_stroke;
  radius = circle_radius;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    this.stroke = (1 - this.currentStep / (this.steps-1)) * circle_stroke;
  }

  ngOnInit(): void {
  }


}
