
<div *ngIf="step == 0">
  <app-page-header title="Change Plan"></app-page-header>
  <app-subscription-change-select
    [selectedPlan]="selectedPlan"
    [plans]="plans"
    [currentPlan]="currentPlan"
    [subscriptionLastChanged]="subscriptionLastChanged"
    [subscriptionChangeFlag]="subscriptionChangeFlag"
    (onSelectedPlan)="clickNext($event)"
    *ngIf="!isLoading"
    ></app-subscription-change-select>
</div>

<app-subscription-change-capacity
  [callboxes]="callboxes"
  [plans]="plans"
  [selectedPlan]="selectedPlan"
  [currentPlan]="currentPlan"
  [isDowngrade]="isDowngrade"
  [forwards]="forwards"
  [selectedCallboxes]="selectedCallboxes"
  [selectedForwards]="selectedForwards"
  (clickedNext)="clickedNextCapacity($event)"
  (clickBack)="clickBackCapacity()"
  *ngIf="step == 1"></app-subscription-change-capacity>

<app-subscription-change-confirm
  [callboxes]="callboxes"
  [plans]="plans"
  [selectedPlan]="selectedPlan"
  [currentPlan]="currentPlan"
  [isDowngrade]="isDowngrade"
  [selectedCallboxes]="selectedCallboxes"
  [selectedForwards]="selectedForwards"
  *ngIf="step == 2"
  (clickBack)="clickedBackConfirm()"></app-subscription-change-confirm>

<div *ngIf="isLoading" class="d-flex justify-content-center">
  <app-large-spinner></app-large-spinner>
</div>
