<div>

  <app-page-header title="My Passes"></app-page-header>


  <div class="page">
    <div *ngIf="!isLoading">

      <div>
        <div class="d-flex justify-content-center">
          <img src="/assets/img/pass-icon.svg" class="header-img grow-in" />
        </div>
        <div class="text-sm mb-3">
          Create passes and share them with your guests so they can enter your residence
        </div>
      </div>

      <div class="mb-3">
        <div class="pass-list-filter-group btn-group" role="group">
          <button type="button" class="btn btn-outline-primary" [ngClass]="{ 'active' : filter == 'active' }" (click)="clickFilter('active')">Active</button>
          <button type="button" class="btn btn-outline-primary" [ngClass]="{ 'active' : filter == 'passed' }"(click)="clickFilter('passed')">Passed</button>
        </div>
        <div *ngFor="let n of items" [routerLink]="[n.shareId]" class="list-row">
          <div class="d-flex">
            <div class="flex-grow-1">
              <div class="pass-location">{{n.callboxName}}</div>
              <span class="pass-times">{{n.startDt | amDateFormat: "MMM DD" }} - {{n.endDt | amDateFormat: "MMM DD" }}</span>
              <br />
              <span class="pass-created">Created {{n.createdAt | amTimeAgo}}</span>
            </div>
            <div>
              <div class="text-danger text-bold text-sm" *ngIf="n.isExpired">Expired</div>
              <div class="text-primary text-bold text-sm" *ngIf="n.isDisabled">Disabled</div>
            </div>
          </div>

        </div>
        <div *ngIf="items && items.length == 0" class="my-5">
          <span class="text-muted">You have no passes at the moment. Try creating one.</span>
        </div>
      </div>

      <div>
        <button class="btn btn-block btn-primary" routerLink="create">New Pass</button>
      </div>
  </div>

    <div *ngIf="isLoading">
      <div class="d-flex justify-content-center">
        <app-page-loader></app-page-loader>

      </div>
    </div>

  </div>
</div>



<!--Preload-->
<img src="/assets/img/pass-icon.svg" class="d-none" />
