export const environment = {
  production: true,
  auth_domain: 'upass.us.auth0.com',
  auth_clientId: 'uu3WANwJidgfLuSgRAqocnk8qL5iXVRN',
  auth_redirect_url: "https://callbox.upass.io/auth/redirect",
  auth_audience: "https://callbox-api.upass.io/mscallbox-prod",
  api_url: "https://callbox-api.upass.io/mscallbox-prod",
  env: "prod",
  landing_privacy_policy_url: "http://www.upass.io/privacy-policy"
};
