<div class="splash-container" [@exitBg]>

    <div class="content-container" *ngIf="isImagesLoaded">

        <!-- Page 1-->
        <div class="content-main" *ngIf="currStep == 0 && !downpage" [@enterExit]>
            <div class="content-header">
                <h2 class="lead">Welcome to uPass</h2>
            </div>
            <div class="image-content">
                <div class="col-8">
                    <img src="/assets/img/splash/splash_1.svg" class="img-fluid">
                </div>
            </div>
            <div class="content-action">
                <button class="btn btn-outline-light btn-block p-2 mb-3" (click)="next()">Next</button>
            </div>
        </div>

        <!-- Page 2-->
        <div class="content-main" *ngIf="currStep == 1 && !downpage" [@enterExit]>
            <div class="content-header">
                <h2 class="lead">You’ve been assigned a phone number</h2>
                <span class="phone-pill">{{uphone | phoneFormat}}</span>
            </div>
            <div class="image-content">
                <div class="col-8">
                    <img src="/assets/img/splash/splash_2.svg" class="img-fluid">
                </div>
            </div>
            <div class="content-action">
                <button class="btn btn-outline-light btn-block p-2 mb-3" (click)="next()">Next</button>
            </div>
        </div>

        <!-- Page 3-->
        <div class="content-main" *ngIf="currStep == 2 && !downpage" [@enterExit]>
            <div class="content-header">
                <h2 class="lead">Notify your building management to use this number</h2>
                <span class="phone-pill">{{uphone | phoneFormat}}</span>
            </div>
            <div class="image-content">
                <div class="col-8">
                    <img src="/assets/img/splash/splash_3.svg" class="img-fluid">
                </div>
            </div>
            <div class="content-action">
                <div class="form-check mb-2"  *ngIf="currStep == LAST_STEP">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" [(ngModel)]="accepted">
                    <label class="form-check-label" for="flexCheckDefault">
                        I have updated my callbox to call my upass number
                    </label>
                  </div>
                <button class="btn btn-outline-light btn-block p-2 mb-3" (click)="next()" [disabled]="!accepted">Done</button>
            </div>
        </div>
        <!-- Footer Controls-->
        <div class="content-footer">
            <div class="row">
                <div class="col">
                    <!-- CAROUSEL -->
                    <div class="carousel">
                        <span class="carousel-item" *ngFor="let n of stepperSteps" [ngClass]="{'active': currStep == n }"></span>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>


<img hidden src="/assets/img/splash/splash_1.svg" (load)="imageLoaded()">
<img hidden src="/assets/img/splash/splash_2.svg" (load)="imageLoaded()">
<img hidden src="/assets/img/splash/splash_3.svg" (load)="imageLoaded()">
