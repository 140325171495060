
<div class="fade-in">

  <app-page-header title="Change Password" [useBrowserBack]="true"></app-page-header>
  <div class="page">
    <div *ngIf="!isLoading">
      <!--body-->
      <form #callboxForm="ngForm" name="callbox" (submit)="save(callboxForm)">

        <div class="text-sm mb-3">
          Password must contain lowercase, upercase, and number and special characters. And must be at least 8 characters in length.
        </div>

        <div class="mb-3">
          <label class="form-label">New Password</label>
          <input class="form-control" type="password" required name="newPassword" [(ngModel)]="newPassword" />
        </div>

        <div class="mb-3">
          <label class="form-label">Confirm Password</label>
          <input class="form-control"  type="password" required name="newPasswordConf" [(ngModel)]="newPasswordConf" />
          <div class="text-danger text-sm" *ngIf="isSubmitted && callboxForm.errors?.['required']">
            Please fill out all fields
          </div>
          <div class="text-danger text-sm" *ngIf="errMsg != null">
            {{errMsg}}
          </div>
        </div>

        <div>
          <button class="btn btn-primary btn-block">Save</button>
        </div>
      </form>
    </div>
    <div *ngIf="isLoading">
      <app-page-loader></app-page-loader>
    </div>
  </div>
</div>

