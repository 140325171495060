import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { mergeMap, Observable, of } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private auth: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      const val = this.auth.isAuthenticated$
        .pipe(
          mergeMap(isLoggedIn => {
            const isApiUrl = request.url.startsWith(environment.api_url);
            if (isLoggedIn && isApiUrl) {
              return new Observable<string>(sub => {
                this.auth.getAccessTokenSilently().subscribe(token => {
                    sub.next(token);
                    sub.complete();
                })
              })
              // return this.auth.getAccessTokenSilently();
            } else {
              //TODO: Not logged in. How to handle?
            }
            return of(false);
          }),
          mergeMap(token => {
            if(token) {
              request = request.clone({
                setHeaders: { Authorization: `Bearer ${token}` }
              });
            }

            return next.handle(request)
          })
        )

        return val;
    }
}
