<div>
  <app-page-header title="Callbox"></app-page-header>

  <div class="page">

    <div *ngIf="!isLoading">

      <!-- pass header-->
      <div class="d-flex justify-content-center">
        <h3 class="text-center"></h3>
      </div>

      <div class="d-flex flex-column align-items-center mt-3">
        <div>
          <img src="/assets/img/callbox-icon.svg" class="page-header-img" />
        </div>
        <div>Manage your callbox</div>
      </div>

      <!-- pass details-->
        <!-- pass details-->
        <div class="row justify-content-center mt-3">
          <div class="col-10">
            <div>
              <span class="text-muted">Nick Name</span>
              <p>{{callbox.name}}</p>
            </div>
            <div>
              <span class="text-muted">Phone</span>
              <p>{{callbox.callboxPhone  | phoneFormat}}</p>
            </div>
            <div class="row">
              <div class="col">
                <span class="text-muted">Code</span>
                <p>{{callbox.callboxCode}}</p>
              </div>
              <div class="col">
                <span class="text-muted">Dial Key</span>
                <p>{{callbox.dialKey}}</p>
              </div>
            </div>
            <div>
              <span class="text-muted">Address</span>
              <p>
                {{callbox.address1}}
                {{callbox.address2}}
                <br />
                {{callbox.city}} {{callbox.state}}, {{callbox.zip}}
                <br />
              </p>
            </div>
            <div class="mb-3">
              <span class="text-muted text-sm">Created {{callbox.createdAt | amTimeAgo}}</span>
            </div>

          </div>
        </div>

          <!--Actions-->
          <div class="card-actions">
            <button class="btn btn-link" type="submit" (click)="deleteCallbox()">
              <i class="fa-solid fa-trash"></i>
              <span>Delete</span>
            </button>


            <button class="btn btn-link" type="submit" [routerLink]="['edit']" [state]="callbox">
              <i class="fa-solid fa-pen"></i>
              <span>Edit</span>
            </button>
          </div>
    </div>

    <div *ngIf="isLoading" class="d-flex justify-content-center align-items-middle">
      <app-page-loader></app-page-loader>
    </div>

  </div>
</div>
