<div *ngIf="step == 0">
  <app-page-header title="Reactivate Plan" [showBack]="false"></app-page-header>
  <app-resubscribe-select
    [plans]="plans"
    [selectedPlan]="selectedPlan"
    (onSelectedPlan)="onSelectedPlan($event)"
    *ngIf="!isLoading"></app-resubscribe-select>
</div>


<div *ngIf="step == 1">
  <app-resubscribe-payment [selectedPlan]="selectedPlan"></app-resubscribe-payment>
</div>

<div *ngIf="isLoading" class="d-flex justify-content-center">
  <app-large-spinner></app-large-spinner>
</div>
