import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ForwardCreateParams } from '@upass/callbox';
import { parsePhone, validatePhone } from 'src/app/common/phone-validation';
import { ForwardService } from 'src/app/services/forward.service';

@Component({
  selector: 'app-forward-create',
  templateUrl: './forward-create.component.html',
  styleUrls: ['./forward-create.component.scss']
})
export class ForwardCreateComponent implements OnInit {
  isSubmitted = false;
  isLoading = false;
  model: ForwardCreateParams = {
    forwardNumber: ""
  };
  phone = "";
  validPhone = false;

  constructor(
    private forwardSvc: ForwardService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
  }


  submit(form: NgForm) {
    this.isSubmitted = true;

    if (!form.valid) {
      return;
    }

    if(!this.validPhone) {
      return;
    }

    //save forward
    this.isLoading = true;
    this.model.forwardNumber = this.phone;
    this.forwardSvc.createForward(this.model).subscribe({
      next: (res) => {
        this.isLoading = false;
        this.router.navigate(['../'], { relativeTo: this.route });
      },
      error: () => {
        this.isLoading = false;
        alert('Error saving forward');
      },
    });
  }


  isValidPhone(value: boolean) {
    this.validPhone = value;
  }
}
