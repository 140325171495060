import { SessionToken, SubscriptionStatus } from '@upass/callbox';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-resubscribe-success',
  templateUrl: './resubscribe-success.component.html',
  styleUrls: ['./resubscribe-success.component.scss']
})
export class ResubscribeSuccessComponent implements OnInit, OnDestroy {

  isLoading = true;
  phone: string;
  checkInterval: Subscription;
  sessionCount = 0;

  constructor(private sessionSvc: SessionService) { }

  ngOnInit(): void {
    //note most of this code is copied from setup/subscription-success
    this.sessionSvc.getToken().subscribe(token => {
      if (token.sst == SubscriptionStatus.SUBSCRIBED) {
        this.handleSubscription(token);
      } else {
        this.startInterval();
      }
    })
  }

  ngOnDestroy(): void {
    if(this.checkInterval){
      this.checkInterval.unsubscribe();
    }
  }


  startInterval() {
    //get session until subscription shows as subscribed
    this.checkInterval = interval(3000).subscribe(() => {
      this.sessionSvc.getSession(true).subscribe({
        next: (token) => {
          let decoded = this.sessionSvc.decode(token);
          if (decoded.sst !== SubscriptionStatus.SUBSCRIBED) {
            console.log("NOT SUBSCRIBED YET");

            if(this.sessionCount == 10) {
              this.checkInterval.unsubscribe();
              alert("Taking too long, there might have been an error. Please verify your card was not charged and contact support.");
            }

            this.sessionCount++;
            return;
          }

          this.checkInterval.unsubscribe();
          this.handleSubscription(decoded);
        },
        error: () => { console.log("Error getting session"); }
      });

    });
  }

  handleSubscription(decoded: SessionToken) {
    this.phone = decoded.upn;
    this.isLoading = false;
  }
}
