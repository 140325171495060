<div class="page mb-3">

  <div class="stepper-wrapper">
    <!--Circle progress-->
    <div class="progress-wrapper">
      <span class="inner-text">{{currentStep}} of {{steps - 1}}</span>
      <svg>
        <circle class="progress-bg" [attr.cx]="radius+5" [attr.cy]="radius+5" [attr.r]="radius"></circle>
        <circle class="progress" [attr.cx]="radius+5" [attr.cy]="radius+5" [attr.r]="radius" [ngStyle]="{ 'stroke-dashoffset': stroke }"></circle>
      </svg>
    </div>
    <div class="stepper-text">
      <span class="stepper-title">{{stepText}}</span>
      <span class="stepper-title-next" *ngIf="stepNextText != null && stepNextText != ''">Next: {{stepNextText}}</span>
    </div>
  </div>

</div>
