import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private auth: AuthService) { }

  ngOnInit(): void {
    this.auth.isAuthenticated$.subscribe(isAuth => {

      if(isAuth){
        const baseUrl = window.location.protocol + "//" + window.location.host;
        this.auth.logout({
          returnTo: baseUrl + "/auth/logout"
        });
      }

    })

  }


  clickLogin() {
    this.auth.loginWithRedirect({
      // returnTo: "/"
    })
  }
}
