<div>

  <app-page-header title="Cancel Subscription"></app-page-header>

  <div class="page">

    <div *ngIf="!isLoading">
      <form>
        <div class="mb-5">
          <div class="d-flex justify-content-center">
            <img src="/assets/img/subscription-cancellation-icon.svg" class="header-img header-img-lg" />
          </div>
          <h3 class="text-center">Sad to see you go</h3>
        </div>

        <div>
            <div>
              <p>What's the biggest reason you wish to cancel?</p>
            </div>

            <div class="mb-3">
              <div class="form-check" *ngFor="let n of options">
                <input class="form-check-input" type="radio" [value]="n" name="flexRadioDefault" [id]="'flexRadioDefault' + n.id" [(ngModel)]="option">
                <label class="form-check-label" [for]="'flexRadioDefault' + n.id">
                  {{n.option}}
                </label>
              </div>
            </div>

            <div class="mb-3">
              <label *ngIf="option === null">Additional Comments</label>
              <label *ngIf="option !== null">{{option.question}}</label>
              <textarea class="form-control" height="5" [(ngModel)]="comments" name="comments"></textarea>
            </div>

            <div class="mb-3 mt-4">
              <div>Your subscription will remain active until <i>{{planEndDate | date}}</i>. You will not be charged again.</div>
            </div>

            <div class="text-danger text-center" *ngIf="requiredError">
              Select a cancellation reason
            </div>

            <div>
              <button class="btn btn-block btn-outline-danger" (click)="clickCancel()">
                Cancel My Subscription
              </button>
            </div>

          </div>
      </form>
    </div>

    <div *ngIf="isLoading">
      <app-page-loader></app-page-loader>
    </div>



  </div>
</div>
