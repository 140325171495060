
<form>
  <app-page-header title="Confirm Plan" [preventBack]="true" (clickedBack)="clickedBack()"></app-page-header>
  <div class="page">

    <div *ngIf="!isLoading">
      <div>
        <h5 class="text-center mt-3">Your new plan</h5>
      </div>


      <!--Confirmation-->
      <!-- Icons-->
      <!-- <div class="product-tiles mt-3">
        <div class="product-tile">
          <i class="fa-solid fa-square-phone"></i>
          <span>{{selectedPlan.callboxes}}</span>
          <span class="product-label">callboxes</span>
        </div>
        <div class="product-tile">
          <i class="fa-solid fa-address-book"></i>
          <span>{{selectedPlan.forwards}}</span>
          <span class="product-label">forward #s</span>
        </div>
      </div> -->

      <div class="my-3">
        <div class="comparison">
          <span>{{currentPlan.name}}</span>
          <i class="fa-solid fa-arrow-right"></i>
          <span>{{selectedPlan.name}}</span>
        </div>
          <div class="comparison">
            <span>{{currentPlan.callboxes}} callboxes</span>
            <i class="fa-solid fa-arrow-right"></i>
            <span>{{selectedPlan.callboxes}}  callboxes</span>
          </div>
          <div class="comparison">
            <div>{{currentPlan.forwards}} forwards</div>
            <i class="fa-solid fa-arrow-right"></i>
            <div>{{selectedPlan.forwards}} forwards</div>
          </div>
      </div>


      <div class="my-4 text-center">
        <i>
          These changes are prorated and will be applied <strong>immediately</strong>. If you downgraded your subscription, it will be credited to the next billing cycle.
          You may also lose any active promotions.
        </i>
        <!--TODO Add a link with help info one day-->
      </div>

      <div class="my-4">
        <div class="d-flex">
          <span class="flex-grow-1">Next Billing Date: </span>
          <i class="text-right"> {{nextBillingDate | date}}</i>
        </div>
        <div class="d-flex">
          <span class="flex-grow-1">Total Due Today: </span>
          <i class="text-right" *ngIf="preview.total > 0">${{preview.total / 100 | number : '1.2-2'}}</i>
          <i class="text-right" *ngIf="preview.total <= 0">$0.00</i>
        </div>
      </div>


      <div>
        <button class="btn btn-primary btn-block" (click)="clickConfirm()">
          Confirm
        </button>
      </div>
    </div>


    <div *ngIf="isLoading">
      <app-page-loader></app-page-loader>
    </div>

  </div>
</form>
