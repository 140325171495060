import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { SessionService } from './session.service';
import { mergeMap } from 'rxjs';

/**
 * Service to wrap api calls
 */
@Injectable({
  providedIn: 'root'
})
export class AnonApiService {
  host = environment.api_url;

  constructor(private sessionSvc: SessionService, private http: HttpClient) {}

  post<T>(path: string, payload: any) {
    return this.http.post<T>(`${this.host}${path}`, payload, {});
  }

  get<T>(path: string, query?: any) {
    return this.http.get<T>(`${this.host}${path}`, {
      params: query
    });
  }

  delete<T>(path: string, query?: any) {
    return this.http.delete<T>(`${this.host}${path}`, {
      params: query
    });
  }

  put<T>(path: string, payload: any) {
    return this.http.put<T>(`${this.host}${path}`, payload, {});
  }


}
