<div class="page" *ngIf="!isLoading">
  <div>
    <div class="d-flex justify-content-center">
      <img src="/assets/img/callbox-icon.svg" class="header-img" />
    </div>
    <h3 class="text-center">Callbox</h3>
    <p class="text-muted text-center">Setup your your callbox so upass can recognize it. You can add more later.</p>
  </div>
  <form #form="ngForm" name="callbox">
    <div>
        <app-phone-input (isValidPhone)="isValidPhone($event)"  [(value)]="model.callboxPhone" [showErrors]="isSubmitted"></app-phone-input>
    </div>
    <div class="mb-3">
      <label>Entry Code</label>
      <div class="text-muted text-xs">This is the key you press on your phone to let the guest in</div>
      <select class="form-control" required name="dialKey" [(ngModel)]="model.dialKey">
        <option>0</option>
        <option>1</option>
        <option>2</option>
        <option>3</option>
        <option>4</option>
        <option>5</option>
        <option>6</option>
        <option>7</option>
        <option>8</option>
        <option>9</option>
        <option>#</option>
        <option>*</option>
      </select>
    </div>
    <div class="mb-3">
      <label>Gate Code</label>
      <div class="text-muted text-xs">This is the code your guests use at the callbox</div>
      <input type="text" name="gateCode" class="form-control" placeholder="#123" required [(ngModel)]="model.callboxCode"/>
    </div>
    <div class="text-danger text-sm" *ngIf="isSubmitted && form.form.invalid">
      Please fill out all fields
    </div>

  </form>
</div>

<app-setup-step-buttons (next)="clickNext()" (previous)="clickPrevious()"[showPrevious]="false" *ngIf="!isLoading"></app-setup-step-buttons>

<div class="page" *ngIf="isLoading">
  <div class="d-flex justify-content-center">
    <app-page-loader></app-page-loader>
  </div>
</div>
