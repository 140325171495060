import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CallBoxVM } from '@upass/callbox';
import { CallboxService } from 'src/app/services/callbox.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-callbox-view',
  templateUrl: './callbox-view.component.html',
  styleUrls: ['./callbox-view.component.scss']
})
export class CallboxViewComponent implements OnInit {
  callbox: CallBoxVM = null;
  callboxId: string = null;
  isLoading = true;

  constructor(private callboxSvc: CallboxService, private sessionSvc: SessionService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
     //get route params
     this.route.paramMap.subscribe(params => {
      this.callboxId = params.get("callboxId");

      this.callboxSvc.getCallBox(this.callboxId).subscribe(res => {
        this.callbox = res;
        this.isLoading = false;
      }, err => {
        alert("Callbox not found.");
      })
    })
  }

  deleteCallbox() {
    if(confirm("If you delete your callbox, all associated passess will be deleted. Are you sure?")) {
      this.isLoading = true;
      this.callboxSvc.deleteCallBox(this.callboxId).subscribe(res => {
        this.callboxSvc.invalidateCache();
        this.sessionSvc.getSession(true).subscribe(res => {
          this.router.navigate(['../'], { relativeTo: this.route });
        })
      })
    }
  }

}
