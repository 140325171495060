import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
// import { NotificationService } from "@app/services/notification.service";

@Component({
   selector: "app-page-loader",
   styleUrls: ['./page-loader.component.scss'],
   templateUrl: './page-loader.component.html',
})
export class PageLoaderComponent implements OnInit {



   constructor() {}

   ngOnInit(): void {

   }

}
