import { InvoiceItem, ProductResponseItem } from '@upass/callbox';
import { trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { SessionService } from 'src/app/services/session.service';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'app-billing-history',
  templateUrl: './billing-history.component.html',
  styleUrls: ['./billing-history.component.scss']
})
export class BillingHistoryComponent implements OnInit {
  invoices: InvoiceItem[] = null;
  product: ProductResponseItem;
  products: ProductResponseItem[];
  isLoadingInvoices = true;
  isLoadingPlan = true;
  nextBillingDate: Date;

  constructor(private subSvc: SubscriptionService, private sessionSvc: SessionService) { }

  ngOnInit(): void {

    this.sessionSvc.getToken().subscribe(session => {

      this.subSvc.listPrices().subscribe(products => {
        this.products = products;
        this.product = this.products.find(p => p.productId == session.pid);
        this.nextBillingDate =  moment(session.sxp).add(1, 'day').toDate();
        this.isLoadingPlan = false;
      });
    });

    this.subSvc.listInvoices().subscribe(res => {
      this.isLoadingInvoices = false;
      this.invoices = res;

      const latest = res.reduce((prev, curr, inx, arr) => {
        if(curr.periodEnd > prev.periodEnd) {
          return curr;
        } else {
          return prev;
        }
      });

      console.log(res);
    });


  }


  clickInvoice(n: InvoiceItem){
    window.open(n.receiptUrl, "_blank");
  }

}
