<div>

  <app-page-header title="View Pass"></app-page-header>

  <div class="page">

    <div *ngIf="!isLoading">

      <div class="d-flex flex-column align-items-center mt-3">
        <div>
          <img src="https://cdn3.iconfinder.com/data/icons/landscape-23/135/10-512.png" class="page-header-img" />
        </div>
        <div>Invite a guest to your residence</div>
      </div>

      <!-- pass details-->
      <form (submit)="submit()">

        <!-- pass details-->
        <div class="row justify-content-center mt-3">
          <div class="col-10">
            <app-pass-time-labels [startDt]="pass.startDt" [endDt]="pass.endDt"></app-pass-time-labels>
            <div>
              <span class="pass-label">LOCATION</span>
              <p>{{pass.callboxName}}</p>
            </div>
            <div>
              <span class="pass-label">ADDRESS</span>
              <p>
                {{pass.address1}}
                {{pass.address2}}
                <br />
                {{pass.city}} {{pass.state}}, {{pass.zip}}
              </p>
            </div>

            <!-- Share Link-->
            <div class="mb-3" *ngIf="!pass.isExpired">
              <div class="text-sm sm text-muted">
                <small>Share the link below with your guests</small>
              </div>
              <div>
                <input readonly [value]="shareUrl" class="form-control flex-grow-1" />
              </div>
            </div>

            <div class="text-danger" *ngIf="pass.isExpired">
              This pass is expired, please create a new one.
            </div>
          </div>
        </div>

          <!--Actions-->
          <div class="card-actions" *ngIf="!pass.isExpired">
            <!-- <button class="btn btn-link" type="submit" (click)="disable()">
              <i class="fa-solid fa-ban"></i>
              <span>Deactivate</span>
            </button> -->

            <upass-share-button [shareUrl]="shareUrl" title="Come visit me with uPass!"></upass-share-button>
            <button class="btn btn-link" type="submit" [routerLink]="['edit']">
              <i class="fa-solid fa-pen"></i>
              <span>Edit</span>
            </button>
          </div>
      </form>
    </div>

    <div *ngIf="isLoading" class="d-flex justify-content-center align-items-middle">
      <app-page-loader></app-page-loader>
    </div>

  </div>
</div>
