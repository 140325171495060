import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionStatus } from '@upass/callbox';
import moment from 'moment';
import { ApiService } from './services/api.service';
import { CallboxService } from './services/callbox.service';
import { SessionService } from './services/session.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  year: number;
  privacyPolicyUrl = environment.landing_privacy_policy_url;

  constructor(private sessionSvc: SessionService, private router: Router, private route: ActivatedRoute, private api: ApiService, private callBoxSvc: CallboxService) {

  }

  ngOnInit(): void {
    this.year = moment().year();

    //get session token
    this.sessionSvc.getSession().subscribe({
      next: res => {
        const token = this.sessionSvc.decode(res);
        console.log("Session token", res);

        //navigate to get started if New
        if(token.sst == SubscriptionStatus.NEW){
          this.router.navigate(["/get-started"], {
            queryParamsHandling: "preserve"
          });
        } else if (token.sst == SubscriptionStatus.INACTIVE) {
          //redirect to resubscribe page
          this.route.url.subscribe(url => {
            if(url[0].path != 'resubscribe') {
              this.router.navigate(["/resubscribe"], {
                queryParamsHandling: "preserve"
              });
            }
          })
        }

        //reload callboxes
        this.callBoxSvc.listCallboxes().subscribe(res => {});
      },
      error: () => {
        alert("Critical error loading session data.");
      }
    });


  }

}
