import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pass-time-labels',
  template: `
    <div class="row mt-3 mb-3">
      <div class="col pass-time-label">
        <label>FROM</label>
        <span>{{startDt | amDateFormat:'ll' }}</span>
        <span>{{startDt | amDateFormat:'LT' }}</span>
      </div>
      <div class="col pass-time-label">
        <label>TO</label>
        <span>{{endDt | amDateFormat:'ll' }}</span>
        <span>{{endDt | amDateFormat:'LT' }}</span>
      </div>
    </div>
  `,
  styleUrls: ['./pass-time-labels.component.scss']
})
export class PassTimeLabelsComponent implements OnInit {
  @Input() startDt: string;
  @Input() endDt: string;

  constructor() { }

  ngOnInit(): void {
  }

}
