import { CallBoxVM, ForwardVM, ProductResponseItem, SessionToken } from '@upass/callbox';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ChangeDetectorRef, SimpleChanges, AfterViewInit} from '@angular/core';

@Component({
  selector: 'app-subscription-change-capacity',
  templateUrl: './subscription-change-capacity.component.html',
  styleUrls: ['./subscription-change-capacity.component.scss']
})
export class SubscriptionChangeCapacityComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() selectedPlan: ProductResponseItem;
  @Input() callboxes: CallBoxVM[];
  @Input() forwards: ForwardVM[];
  @Input() isDowngrade = false;
  @Input() plans: ProductResponseItem[];
  @Input() currentPlan: ProductResponseItem;
  @Output() clickBack = new EventEmitter();
  @Output() clickedNext = new EventEmitter<{ callboxes: CallBoxVM[], forwards: ForwardVM[]}>();
  @Input() selectedCallboxes: CallBoxVM[] = [];
  @Input() selectedForwards: ForwardVM[] = [];
  maxDeleteCallboxes = 0;
  maxDeleteForwards = 0;

  isLoading = false;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {

    if(changes['selectedPlan'] || changes['callboxes']) {
      this.maxDeleteCallboxes = this.callboxes.length - this.selectedPlan.callboxes;
    }

    if(changes['selectedPlan'] || changes['forwards']) {
      this.maxDeleteForwards = this.forwards.length - this.selectedPlan.forwards;
    }

  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  clickedBack() {
    this.clickBack.emit();
  }

  clickNext() {
    if(this.maxDeleteCallboxes !== this.selectedCallboxes.length || this.maxDeleteForwards !== this.selectedForwards.length) {
      return;
    }

    this.clickedNext.emit({
      callboxes: this.selectedCallboxes,
      forwards: this.selectedForwards
    })
  }


  updateSelectedCallbox(event: any, callbox: CallBoxVM) {
    if (event.target.checked) {
      if (this.selectedCallboxes.indexOf(callbox) < 0) {
            this.selectedCallboxes.push(callbox);
      }
     } else {
      if (this.selectedCallboxes.indexOf(callbox) > -1) {
        this.selectedCallboxes.splice(this.selectedCallboxes.indexOf(callbox), 1);
      }
    }

    console.log("Selected cb", this.selectedCallboxes);
  }

  updateSelectedForward(event: any, forward: ForwardVM) {
    if (event.target.checked) {
      if (this.selectedForwards.indexOf(forward) < 0) {
            this.selectedForwards.push(forward);
      }
     } else {
      if (this.selectedForwards.indexOf(forward) > -1) {
        this.selectedForwards.splice(this.selectedForwards.indexOf(forward), 1);
      }
    }

    console.log("Selected fw", this.selectedForwards);
  }
}
