<div class="fade-in">

  <app-page-header title="Edit Pass"></app-page-header>

  <div class="page">

    <div *ngIf="!isLoading">
      <!-- pass header-->
      <div class="d-flex flex-column align-items-center mt-3">
        <div>
          <img src="https://cdn3.iconfinder.com/data/icons/landscape-23/135/10-512.png" class="page-header-img grow-in" />
        </div>
        <div>Invite a guest to your residence</div>
      </div>

      <!-- pass details-->
      <form (submit)="submit()">

        <div class="row mt-2">
          <div class="col-12 mb-3">
            <label>From</label>
            <input type="datetime-local" class="form-control" name="start" upassDate [(ngModel)]="startDate" required [min]="minDate"/>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mb-3">
            <label>To</label>
            <input type="datetime-local" class="form-control" name="end" upassDate [(ngModel)]="endDate" required [min]="minDate" />
          </div>
        </div>

        <div class="form-check mb-3">
          <input class="form-check-input" type="checkbox" id="flexCheckDefault" name="isDisabled" [(ngModel)]="isDisabled">
          <label class="form-check-label" for="flexCheckDefault">
            Disable pass from being used
          </label>
        </div>

        <div *ngIf="errors.length > 0" class="mb-3">
          <div *ngFor="let n of errors" class="text-danger">{{n}}</div>
        </div>
        <!--Actions-->
        <div class="d-grid gap-2">
          <button class="btn btn-primary" type="submit" [disabled]="pass.isExpired">Update</button>
        </div>
      </form>
    </div>

    <div *ngIf="isLoading">
      <app-page-loader></app-page-loader>
    </div>


  </div>
</div>
