import { Injectable } from '@angular/core';
import { ForwardCreateParams, ForwardPutParams, ForwardVM} from '@upass/callbox';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ForwardService {

  constructor(private api: ApiService) { }

  /** Create a new callbox */
  createForward(params: ForwardCreateParams) {
    return this.api.post<ForwardVM>("/forward", params);
  }

  /** Returns list of forwards */
  listForwards() {
    //TODO: Cache this
      return this.api.get<ForwardVM[]>("/forward")
  }

  /** Returns list of forwards */
  getForward(forwardId: string) {
    return this.api.get<ForwardVM>("/forward/" + forwardId);
  }

  /** Update forward */
  putForward(params: ForwardPutParams) {
      return this.api.put<ForwardVM>("/forward", params);
  }

  /** Update list of forwards */
  putForwards(params: ForwardPutParams[]) {
    return this.api.put<ForwardVM[]>("/forward", params);
  }


  /** Delete a forward */
  deleteForwards(forwardId: string) {
    return this.api.delete<any>("/forward/" + forwardId);
  }
}
