import { CallBoxVM, ForwardVM, ProductResponseItem, SessionToken, SubcriptionUpdatePreviewResponse } from '@upass/callbox';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import moment from 'moment';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from 'src/app/services/session.service';
import { CallboxService } from 'src/app/services/callbox.service';
import { ForwardService } from 'src/app/services/forward.service';
import { forkJoin, Observable, of } from 'rxjs';

@Component({
  selector: 'app-subscription-change-confirm',
  templateUrl: './subscription-change-confirm.component.html',
  styleUrls: ['./subscription-change-confirm.component.scss']
})
export class SubscriptionChangeConfirmComponent implements OnInit, OnChanges {
  @Input() selectedPlan: ProductResponseItem;
  @Input() callboxes: CallBoxVM[];
  @Input() isDowngrade = false;
  @Input() plans: ProductResponseItem[];
  @Input() currentPlan: ProductResponseItem;
  @Input() selectedCallboxes: CallBoxVM[] = [];
  @Input() selectedForwards: ForwardVM[] = [];
  @Output() clickBack = new EventEmitter();
  isLoading = false;
  nextBillingDate: Date;
  preview: SubcriptionUpdatePreviewResponse = null;
  prorateDate: string;

  constructor(private subSvc: SubscriptionService, private callboxSvc: CallboxService, private forwardSvc: ForwardService, private sessionSvc: SessionService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['selectedPlan'] && !this.isLoading) {
      this.isLoading = true;
      this.subSvc.updatePreviewSubscription(this.selectedPlan.productId).subscribe(res => {
        this.preview = res;
        this.isLoading = false;
        this.nextBillingDate = moment(res.nextBillingDate).toDate();
        this.prorateDate = res.prorateDate;
      })
    }
  }


  clickConfirm() {

    const tasks = [
      // ...this.selectedCallboxes.map(n => this.callboxSvc.deleteCallBox(n.callboxId)),
      // ...this.selectedForwards.map(n => this.forwardSvc.deleteForwards(n.forwardId)),
      ...this.selectedCallboxes.map(n =>
        new Observable<string>(sub => {
          this.callboxSvc.deleteCallBox(n.callboxId).subscribe(() => {
            console.log("callbox deleted: " + n.callboxId);
            sub.next();
            sub.complete();
          })
        })
      ),
      ...this.selectedForwards.map(n =>
        new Observable<string>(sub => {
          this.forwardSvc.deleteForwards(n.forwardId).subscribe(() => {
            console.log("forward deleted: " + n.forwardId);
            sub.next();
            sub.complete();
          })
        })
      ),
      of(true)
    ]

    console.log(tasks);
    this.isLoading = true;
    forkJoin(tasks).subscribe(deletions => {
      this.subSvc.updateSubscription(this.selectedPlan.productId, this.prorateDate).subscribe(res => {
        this.sessionSvc.getSession(true).subscribe(ses => {
          this.router.navigate(["../"], { relativeTo: this.route });
        });
      })
    })

  }


  clickedBack () {
    this.clickBack.emit();
  }
}
