import { Injectable } from '@angular/core';
import { CancelSubscriptionResponse, CreateCheckoutSessionResponse, CreateSessionRequest, CreateUpdatePaymentSessionResponse, InvoiceItem, PaymentMethodItem, ProductResponseItem, SubscriptionCancelRequest, SubcriptionUpdatePreviewResponse, ValidatePromoResponse } from "@upass/callbox";
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(private api: ApiService) { }


  createSession(options: CreateSessionRequest) {
    return this.api.post<CreateCheckoutSessionResponse>("/subscription/session", options);
  }

  createResubscribeSession(options: CreateSessionRequest) {
    return this.api.post<CreateCheckoutSessionResponse>("/subscription/session/resubscribe", options);
  }

  listPrices() {
    return this.api.get<ProductResponseItem[]>("/subscription/product");
  }

  cancelSubscription(reason?: SubscriptionCancelRequest) {
    return this.api.delete<CancelSubscriptionResponse>("/subscription", reason);
  }

  reactivateSubscription() {
    return this.api.post<any>("/subscription/reactivate", {});
  }

  updateSubscription(productId: number, prorateDate: string) {
    return this.api.put<any>("/subscription", { productId: productId, prorateDate: prorateDate });
  }

  updatePreviewSubscription(productId: number) {
    return this.api.get<SubcriptionUpdatePreviewResponse>("/subscription/preview", { productId: productId });
  }

  listInvoices() {
    return this.api.get<InvoiceItem[]>("/subscription/invoice");
  }

  listPaymentMethods() {
    return this.api.get<PaymentMethodItem[]>("/subscription/payment");
  }

  updatePaymentMethod() {
    return this.api.get<CreateUpdatePaymentSessionResponse>("/subscription/payment/session");
  }

  deletePaymentMethod(paymentMethodId: string) {
    return this.api.delete<any>("/subscription/payment/" + paymentMethodId);
  }

  setPreferredPaymentMethod(paymentMethodId: string) {
    return this.api.put<any>(`/subscription/payment/${paymentMethodId}/preferred`, {});
  }

  validatePromo(promoCode: string, productId: string) {
    return this.api.post<ValidatePromoResponse>(`/promo/validate`, {
      promo: promoCode,
      productId: productId
    })
  }
}
