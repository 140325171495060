import { Component, Input, SimpleChanges } from "@angular/core";


const circle_radius = 40;
const circle_diameter = 2 * Math.PI * circle_radius;

/**
 * Progress Circle
 */
@Component({
    selector: 'app-progress-circle',
    templateUrl: './progress-circle.component.html',
    styleUrls: [
        "./progress-circle.component.scss"
    ]
  })
export class ProgressCircleComponent {

  @Input() value: number = 0;
  @Input() maxValue: number = 100;
  @Input() minValue: number = 0;
  @Input() text: string = "";


  diameter = circle_diameter;
  stroke = 0;
  radius = circle_radius;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    this.update();
  }

  ngOnInit(): void {
    this.update();
  }

  update() {
    this.stroke = (1 - (this.value / this.maxValue)) * circle_diameter;
  }
}
