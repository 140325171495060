import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { AuthRedirectComponent } from './auth/auth-redirect/auth-redirect.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { CallboxCreateComponent } from './callbox/callbox-create/callbox-create.component';
import { CallboxEditComponent } from './callbox/callbox-edit/callbox-edit.component';
import { CallboxListComponent } from './callbox/callbox-list/callbox-list.component';
import { CallboxViewComponent } from './callbox/callbox-view/callbox-view.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ForwardCreateComponent } from './forward/forward-create/forward-create.component';
import { ForwardEditComponent } from './forward/forward-edit/forward-edit.component';
import { ForwardListComponent } from './forward/forward-list/forward-list.component';
import { GuestPassViewComponent } from './guest/guest-pass-view/guest-pass-view.component'
import { PassCreateComponent } from './pass/pass-create/pass-create.component';
import { PassEditComponent } from './pass/pass-edit/pass-edit.component';
import { PassListComponent } from './pass/pass-list/pass-list.component';
import { PassViewComponent } from './pass/pass-view/pass-view.component';
import { ResubscribeSuccessComponent } from './resubscribe/resubscribe-success/resubscribe-success.component';
import { ResubscribeComponent } from './resubscribe/resubscribe.component';
import { BillingHistoryComponent } from './settings/billing-history/billing-history.component';
import { NotificationsComponent } from './settings/notifications/notifications.component';
import { PaymentMethodComponent } from './settings/payment-method/payment-method.component';
import { ChangeEmailComponent } from './settings/profile/change-email/change-email.component';
import { ChangeNameComponent } from './settings/profile/change-name/change-name.component';
import { ChangePasswordComponent } from './settings/profile/change-password/change-password.component';
import { ChangePhoneComponent } from './settings/profile/change-phone/change-phone.component';
import { ChangeUsernameComponent } from './settings/profile/change-username/change-username.component';
import { ProfileComponent } from './settings/profile/profile.component';
import { SettingsComponent } from './settings/settings.component';
import { SubscriptionCancelComponent } from './settings/subscription/subscription-cancel/subscription-cancel.component';
import { SubscriptionChangeCapacityComponent } from './settings/subscription/subscription-change/subscription-change-capacity/subscription-change-capacity.component';
import { SubscriptionChangeConfirmComponent } from './settings/subscription/subscription-change/subscription-change-confirm/subscription-change-confirm.component';
import { SubscriptionChangeComponent } from './settings/subscription/subscription-change/subscription-change.component';
import { SubscriptionComponent } from './settings/subscription/subscription.component';
import { SetupContainerComponent } from './setup/setup-container/setup-container.component';

const TITLE_SUFFIX = " | uPass";

function getTitle(title: string) {
  return title + TITLE_SUFFIX;
}

const routes: Routes = [
  { path: "guest-pass", component: GuestPassViewComponent, title: getTitle("Guest Pass") },
  { path: "pass/create", component: PassCreateComponent, canActivate: [AuthGuard], title: getTitle("Create a Pass") },
  { path: "pass/:shareId/edit", component: PassEditComponent, canActivate: [AuthGuard], title: getTitle("My Guest Pass")},
  { path: "pass/:shareId", component: PassViewComponent, canActivate: [AuthGuard], title: getTitle("Edit Pass")},
  { path: "pass", component: PassListComponent, canActivate: [AuthGuard], title: getTitle("My Passes") },
  { path: "callbox", component: CallboxListComponent, canActivate: [AuthGuard], title: getTitle("My Callboxes") },
  { path: "callbox/create", component: CallboxCreateComponent, canActivate: [AuthGuard], title: getTitle("Create a Callbox") },
  { path: "callbox/:callboxId/edit", component: CallboxEditComponent, canActivate: [AuthGuard], title: getTitle("Edit Callbox") },
  { path: "callbox/:callboxId", component: CallboxViewComponent, canActivate: [AuthGuard], title: getTitle("My Callbox") },
  { path: "forward", component: ForwardListComponent, canActivate: [AuthGuard], title: getTitle("My Forward List") },
  { path: "forward/create", component: ForwardCreateComponent, canActivate: [AuthGuard], title: getTitle("Create a Forward") },
  { path: "forward/:phone", component: ForwardEditComponent, canActivate: [AuthGuard], title: getTitle("Edit Forward") },
  { path: "resubscribe", component: ResubscribeComponent, canActivate: [AuthGuard], title: getTitle("Resubscribe")  },
  { path: "resubscribe/success", component: ResubscribeSuccessComponent, canActivate: [AuthGuard], title: getTitle("Resubscribed")  },
  { path: "settings", component: SettingsComponent, canActivate: [AuthGuard], title: getTitle("Settings")  },
  { path: "settings/profile", component: ProfileComponent, canActivate: [AuthGuard], title: getTitle("My Profile")  },
  { path: "settings/profile/phone", component: ChangePhoneComponent, canActivate: [AuthGuard], title: getTitle("Change Phone")  },
  { path: "settings/profile/email", component: ChangeEmailComponent, canActivate: [AuthGuard], title: getTitle("Change Email")  },
  { path: "settings/profile/username", component: ChangeUsernameComponent, canActivate: [AuthGuard], title: getTitle("Change Username")  },
  { path: "settings/profile/name", component: ChangeNameComponent, canActivate: [AuthGuard], title: getTitle("Change Name")  },
  { path: "settings/profile/password", component: ChangePasswordComponent, canActivate: [AuthGuard], title: getTitle("Change Password")  },
  { path: "settings/notifications", component: NotificationsComponent, canActivate: [AuthGuard], title: getTitle("Notification Settings")  },
  { path: "settings/subscription", component: SubscriptionComponent, canActivate: [AuthGuard], title: getTitle("My Subscription")  },
  { path: "settings/subscription/change", component: SubscriptionChangeComponent, canActivate: [AuthGuard], title: getTitle("Change Subscription")  },
  { path: "settings/subscription/cancel", component: SubscriptionCancelComponent, canActivate: [AuthGuard], title: getTitle("Cancel Subscription")  },
  { path: "settings/payment-method", component: PaymentMethodComponent, canActivate: [AuthGuard], title: getTitle("Payment Methods")  },
  { path: "settings/billing", component: BillingHistoryComponent, canActivate: [AuthGuard], title: getTitle("Billing History")  },
  { path: "auth/redirect", component: AuthRedirectComponent, title: getTitle("Redirect") },
  { path: "auth/logout", component: LogoutComponent, title: getTitle("Logout")  },
  { path: "get-started", component: SetupContainerComponent, canActivate: [AuthGuard], title: getTitle("Get Started")  },
  { path: "dashboard", component: DashboardComponent, canActivate: [AuthGuard], title: getTitle("Dashboard")  },
  { path: "", redirectTo: "dashboard", pathMatch: 'full' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
