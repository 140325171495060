<form>
  <div class="page">
    <div>
      <h5 class="text-center mt-3">Select a Plan</h5>
      <div *ngIf="!subscriptionChangeFlag">
        Changes to your plan will be applied immediately
      </div>
    </div>

    <div class="text-danger my-3" *ngIf="subscriptionChangeFlag">
      You've changed your subscription too recently please allow up to 48 hours.
    </div>

    <div class="card plan-card selectable" *ngFor="let n of plans" (click)="clickPlan(n)"
      [ngClass]="{ 'selected' : newPlan && newPlan.productId == n.productId }">
      <div>
        <input class="form-check-input" type="radio" [value]="n" name="flexRadioDefault"
          [id]="'flexRadioDefault' + n.productId" [(ngModel)]="newPlan">
        <label class="form-check-label plan-name mx-2" [for]="'flexRadioDefault' + n.productId">
          {{n.name}}
        </label>
      </div>

      <div class="plan-header flex-grow-1 text-muted" [innerHTML]="n.header"></div>
      <!-- Icons-->
      <div class="product-tiles mt-3">
        <div class="product-tile">
          <i class="fa-solid fa-square-phone"></i>
          <span>{{n.callboxes}}</span>
          <span class="product-label">callboxes</span>
        </div>
        <div class="product-tile">
          <i class="fa-solid fa-address-book"></i>
          <span>{{n.forwards}}</span>
          <span class="product-label">forward #s</span>
        </div>
      </div>

      <div>
        <span class="plan-cost">${{n.price / 100 }}</span>
        <span class="plan-frequency">/month</span>
      </div>

      <div class="flex-grow-1" [innerHTML]="n.description"></div>
    </div>

    <div *ngIf="currentPlan == newPlan" class="text-danger">
      You are already subscribed to this plan.
    </div>
    <div>
      <button class="btn btn-primary btn-block" [disabled]="newPlan == null || currentPlan == newPlan || subscriptionChangeFlag" (click)="clickNext()">
        {{ newPlan || currentPlan == newPlan? "Next": "Pick a Plan"}}
      </button>
    </div>

  </div>
</form>
