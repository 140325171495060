import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProductResponseItem, SubscriptionStatus } from '@upass/callbox';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { SessionService } from 'src/app/services/session.service';
import { SubscriptionService } from 'src/app/services/subscription.service';


@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit, OnDestroy {
  maxCallboxes = 0;
  currCallboxes = 0;
  isLoading = true;
  products: ProductResponseItem[];
  product: ProductResponseItem;
  isCancelled = false;
  subEndsAt: string;
  sessionSub: Subscription;

  constructor(private subSvc: SubscriptionService, private sessionSvc: SessionService) { }

  ngOnInit(): void {
    console.log("NGONINIT")
    this.sessionSub = this.sessionSvc.getSession().subscribe(res => {
      console.log("RUNNING SESSION START INIT")
      let token = this.sessionSvc.decode(res);
      this.maxCallboxes = token.cbm;
      this.currCallboxes = token.cbc;
      this.isCancelled = token.sst === SubscriptionStatus.CANCELLED || token.sst === SubscriptionStatus.INACTIVE;
      this.subEndsAt = moment(token.sxp).format('lll');

      this.subSvc.listPrices().subscribe(products => {
        this.products = products;
        this.product = this.products.find(p => p.productId == token.pid)
        this.isLoading = false;

      });
    });
  }

  ngOnDestroy(): void {
    if(this.sessionSub) {
      this.sessionSub.unsubscribe();
    }
  }

  reactivate() {
    if(!confirm("Are you sure you want to reactivate your subscription?")) {
      return;
    }

    this.isLoading = true;
    this.subSvc.reactivateSubscription().subscribe(res => {
      this.sessionSvc.getSession(true).subscribe(token => {
        const session = this.sessionSvc.decode(token);
        this.isCancelled = false;
        this.isLoading = false;
      })
    })

  }
}
