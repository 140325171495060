import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateCallBox, SubscriptionStatus } from '@upass/callbox';
import { SessionService } from 'src/app/services/session.service';
import { SetupModel } from '../setup.model';

const CONFIG_KEY = "get_started_config";

@Component({
  selector: 'app-setup-container',
  templateUrl: './setup-container.component.html',
  styleUrls: ['./setup-container.component.scss']
})
export class SetupContainerComponent implements OnInit {
  isLoading = true;

  currentStep = 0;
  steps = [
    { title: "Choose your plan", next: "Payment" },
    { title: "Payment", next: "Configure Callbox"},
    { title: "Configure Callbox", next: "Add an address" },
    { title: "Add an address", next: "Get Notified" },
    { title: "Get Notified", next: "Add a Forward" },
    { title: "Add a Forward", next: "Success" },
    { title: "Success", next: "" }
  ];

  callboxModel: SetupModel = {
    name: "",
    callboxPhone: "",
    callboxCode: "",
    dialKey: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    productId: null,
    isPaid: false,
    itemId: "",
    forward: "",
    phoneVerified: false,
    userPhone: ""
  };


  constructor(private route: ActivatedRoute, private router: Router, private sessionService: SessionService, ) { }

  ngOnInit(): void {
    this.getConfig();

    this.route.queryParamMap.subscribe(params => {
      let step = params.get("step");

      if (!step){
        this.router.navigate([], { relativeTo: this.route, queryParams: { step: 0 }})
      } else {
        this.currentStep = parseInt(step);
      }
    });

    this.sessionService.getToken().subscribe(res => {
      this.callboxModel.isPaid = res.sst == SubscriptionStatus.SUBSCRIBED;
      this.callboxModel.phoneVerified = res.pnv;
      this.callboxModel.userPhone = res.pno;

      this.isLoading = false;

      //Dont allow cancelled\inactive to access get-started
      if(res.sst !== SubscriptionStatus.NEW && res.sst !== SubscriptionStatus.SUBSCRIBED) {
        console.log("")
        this.router.navigate(["/dashboard"])
      }
    })

  }

  nextStep() {
    if(this.currentStep == (this.steps.length - 1)) {
      this.removeConfig();
      this.router.navigateByUrl("/dashboard?splash=true");
      return;
    }

    this.setConfig(this.callboxModel);
    this.currentStep++;
    this.router.navigate([], { relativeTo: this.route, queryParams: { step: this.currentStep }})
  }

  prevStep() {
    this.currentStep--;
    this.router.navigate([], { relativeTo: this.route, queryParams: { step: this.currentStep }})
  }

  onCommited() {
    this.removeConfig();
  }

  private getConfig() {
    const record = window.localStorage.getItem(CONFIG_KEY);
    if(!record) {
      return;
    }

    this.callboxModel = JSON.parse(record);
  }

  private setConfig(record: CreateCallBox) {
    window.localStorage.setItem(CONFIG_KEY, JSON.stringify(record));
  }

  private removeConfig() {
    window.localStorage.removeItem(CONFIG_KEY);
  }
}
