import { Component, OnInit } from '@angular/core';
import { SessionService } from '../services/session.service';
import { SubscriptionService } from '../services/subscription.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  phone = "+1xxxxxxxxxx";
  isLoading = true;
  username = "";;
  isNative = true;
  authProvider = "";

  constructor(private session: SessionService, private subSvc: SubscriptionService) { }

  ngOnInit(): void {
    this.session.getToken().subscribe(res => {
      this.phone = res.upn;
      this.username = res.name;
      this.isLoading = false;
      this.isNative = res.inu;
      this.authProvider = res.uid.split("|")[0];
    });
  }
}
