<div class="page-background"></div>

<div class="container">
  <div class="row justify-content-center">
    <div class="col-lg-3"></div>
    <div class="col col-sm-9 col-md-7 col-lg-5 col-xl-4">
      <router-outlet></router-outlet>
      <footer class="text-sm text-center mt-3">
        <!-- <a href="https://upass.io/">Terms and Conditions</a> |  -->
        <a [href]="privacyPolicyUrl">Privacy Policy</a>
        <br />
        &#169;PB Soft LLC {{year}}
      </footer>
    </div>
    <div class="col-lg-3">

    </div>
  </div>
</div>
