import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from '../services/session.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  name = "there";
  isLoading = true;
  showSplash = false;
  uphone: string = null;

  constructor(private sessionSvc: SessionService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      const showSplash = params.get("splash");

      if(showSplash == "true") {
        this.showSplash = true;
      }
    })

    this.sessionSvc.getToken().subscribe(res => {
      this.isLoading = false;
      this.name = res.name.split(' ')[0];
      this.uphone = res.upn;
    })
  }

  onSplashComplete() {
    this.showSplash = false;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {},
      replaceUrl: true,
    });
  }
}
