<div class="page" *ngIf="!isLoading">
  <div>
    <div class="d-flex justify-content-center">
      <img src="/assets/img/location-icon.svg" class="header-img grow-in" />
    </div>
    <h3 class="text-center">Location</h3>
    <p class="text-muted text-center">Setup your callbox's address. We'll show this on your guests pass for directions.</p>
  </div>
  <form #form="ngForm" name="callbox">
    <div class="mb-3">
      <label class="form-label">Name</label>
      <input type="text" name="name" class="form-control" placeholder="Ex. My Beach House" required [(ngModel)]="model.name"/>
    </div>
    <div class="mb-3">
      <label class="form-label">Address</label>
      <input type="text" name="address" class="form-control" required [(ngModel)]="model.address1" />
    </div>
    <div class="mb-3">
      <label class="form-label">Address Line 2</label>
      <input type="text" name="address2" class="form-control" [(ngModel)]="model.address2"  />
    </div>
    <div class="mb-3">
      <label class="form-label">City</label>
      <input type="text" name="city" class="form-control" required [(ngModel)]="model.city"  />
    </div>
    <div class="row">
      <div class="col mb-3">
        <label class="form-label">State</label>
        <input type="text" name="state" class="form-control" required [(ngModel)]="model.state"/>
      </div>
      <div class="col mb-3">
        <label class="form-label">Zip</label>
        <input type="text" name="zip" class="form-control" required [(ngModel)]="model.zip"/>
      </div>
    </div>
    <div class="text-danger text-sm" *ngIf="isSubmitted && form.form.invalid">
      Please fill out all fields
    </div>
  </form>
</div>

<app-setup-step-buttons (next)="clickNext()" (previous)="clickPrevious()" *ngIf="!isLoading"></app-setup-step-buttons>



<div class="page" *ngIf="isLoading">
  <div class="d-flex justify-content-center">
    <app-page-loader></app-page-loader>
  </div>
</div>
