import { Component, OnInit } from '@angular/core';
import { ForwardPutParams, ForwardVM } from '@upass/callbox';
import { delay, of, Subscription, timeout } from 'rxjs';
import { ForwardService } from 'src/app/services/forward.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-forward-list',
  templateUrl: './forward-list.component.html',
  styleUrls: ['./forward-list.component.scss']
})
export class ForwardListComponent implements OnInit {

  items: ForwardVM[] = [];
  isLoading = true;
  maxForwards = 2;
  currForwards = 0;
  saveTimer: Subscription;

  constructor(private forwardSvc: ForwardService, private sessionSvc: SessionService) { }

  ngOnInit(): void {

    this.forwardSvc.listForwards().subscribe(res => {
      this.items = res.sort((a, b) => a.priorityOrder - b.priorityOrder);
      this.isLoading = false;
      this.currForwards = res.length;
    })

    this.sessionSvc.getSession().subscribe(res => {
      let token = this.sessionSvc.decode(res);
      this.maxForwards = token.cbm;
    });
  }

  onDragUpdate(event: any): void {
    console.log(event);
    const toIndex = event.currentIndex;
    const fromIndex = event.previousIndex;
    this._reorderItem(fromIndex, toIndex);

    if(this.saveTimer) {
      this.saveTimer.unsubscribe();
    }

    this.saveTimer = of(1)
      .pipe(delay(1500))
      .subscribe(res => {
        this.save();
        this.saveTimer.unsubscribe();
      });

  }

  save() {
    const body = this.items.map(i => ({
      forwardNumber: i.forwardNumber,
      forwardId: i.forwardId,
      priorityOrder: i.priorityOrder } as ForwardPutParams));

    this.forwardSvc.putForwards(body).subscribe(res => {
      console.log("Forwards saved");
    })
  }

  private _reorderItem(fromIndex: number, toIndex: number): void {
    const itemToBeReordered = this.items.splice(fromIndex, 1)[0];
    this.items.splice(toIndex, 0, itemToBeReordered);

    this.items.forEach((item, index) => { item.priorityOrder = index });

  }
}
