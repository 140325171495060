import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { validatePhone } from '../phone-validation';
import { parsePhone } from "../phone-validation";

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss']
})
export class PhoneInputComponent implements OnInit, OnDestroy, OnChanges {
  phone = "";
  country = "+1";
  validPhone = false;

  @Input() submitted = false;
  @Input() value: string;
  @Input() showErrors = false;
  @Input() phoneSubText = "This phone number will be called when someone uses your callbox without a pass.";
  @Output() valueChange = new EventEmitter<string>();
  @Output() isValidPhone = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
    if(this.value) {
      const { phone, country } = parsePhone(this.value);

      this.phone = phone;
      this.country = country;
    }
  }

  ngOnDestroy(): void {
    this.valueChange.complete();
    this.isValidPhone.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(!validatePhone(this.value)) {
      console.log("[phone-input]: Input phone value is not valid " + this.value);
      return;
    } else {
      console.log("[phone-input]: Input phone value is VALID " + this.value);
    }

    if(changes['value']) {
      const { phone, country } = parsePhone(this.value);

      this.phone = phone;
      this.country = country;
    }
  }

  onPhoneChange(value: string) {
    this.phone = value;
    this.onChange();
  }

  onCountryChange(value: string) {
    this.country = value;

    this.onChange();
  }

  onChange() {
    //TODO: Rework this so that we can use new AsYouType('US').input()
    const parsedPhone = this.country + this.phone;
    this.validPhone = validatePhone(parsedPhone);
    this.isValidPhone.next(this.validPhone);

    if(this.validPhone) {
      this.valueChange.next(parsedPhone);
    }
  }
}
