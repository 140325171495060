import { Component, OnInit } from '@angular/core';
import { PassService } from 'src/app/services/pass.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { PassViewVM } from "@upass/callbox";
import { CallboxService } from 'src/app/services/callbox.service';

@Component({
  selector: 'app-pass-view',
  templateUrl: './pass-view.component.html',
  styleUrls: ['./pass-view.component.scss']
})
export class PassViewComponent implements OnInit {
  pass: PassViewVM = null;
  shareId: string = null;
  isLoading = true;
  shareUrl = "";

  constructor(private passSvc: PassService, private callboxSvc: CallboxService, private route: ActivatedRoute) { }

  ngOnInit(): void {
     //get route params
     this.route.paramMap.subscribe(params => {
      this.shareId = params.get("shareId");

      this.passSvc.getPass(this.shareId).subscribe(res => {
        this.pass = res;
        this.isLoading = false;
        this.shareUrl = this.passSvc.getShareUrl(res);

        this.callboxSvc.listCallboxes()
      }, err => {
        alert("Pass not found.");
      });
    });



  }

  submit() {

    //post data -- TODO: Save pass
    // this.passSvc.createPass(params).subscribe(res => {
    //   alert("Success");
    // }, err => {
    //   console.log(err);
    // })
  }

  disable() {
      if (confirm("Are you sure you want to deactivate this pass? It can no longer be used by your guest")){

      }
  }
}
