<div>

  <app-page-header title="My Forward List"></app-page-header>

  <div class="page">
    <div *ngIf="!isLoading">
      <div class="row justify-content-center">
        <div class="col-5">
          <app-progress-circle [value]="currForwards" [maxValue]="maxForwards" text="Forwards"></app-progress-circle>
        </div>
      </div>
      <div class="text-center">
        Forward calls to your numbers when a guess arrives without a pass.
      </div>
      <div class="d-flex mb-3">
        <div class="flex-grow-1">
          <a class="text-sm" *ngIf="currForwards >= maxForwards">Upgrade your subscripton to get more forward numbers</a>
        </div>

      </div>
      <div *ngIf="items && items.length > 0">
        <!-- cdkDropList (cdkDropListDropped)="onDragUpdate($event)" -->
        <div >
              <!-- cdkDrag -->
          <div *ngFor="let n of items; let index = index" [routerLink]="n.forwardNumber" class="list-row ml-4 d-flex align-items-center"  [routerLink]="[n.forwardId]" [state]="n">
            <div class="flex-grow-1">
              <i class="fa-solid fa-grip-lines text-muted p-3"></i>
              <span>{{n.forwardNumber | phoneFormat}}</span>
            </div>
            <i class="fa-solid fa-chevron-right px-2"></i>
          </div>
        </div>
        <!-- <div class="text-sm text-muted">Reorder the call forwarding priority</div> -->
      </div>

      <div *ngIf="!items || items.length == 0">
        <p class="text-muted">You don't have any forwards setup. Try creating one.</p>
      </div>

      <div *ngIf="currForwards < maxForwards">
        <button class="btn btn-block btn-primary mt-3" routerLink="create" >Add Forward</button>
      </div>
    </div>



    <div *ngIf="isLoading">
      <div class="d-flex justify-content-center">
        <app-page-loader></app-page-loader>
      </div>
    </div>

  </div>
</div>
