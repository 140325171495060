<div>
    <div class="mb-3">
      <label>Country Code</label>
      <app-country-select [country]="country" (countryChange)="onCountryChange($event)" ></app-country-select>
  </div>

  <div class="mb-3">
    <label>Phone</label>
    <div class="text-muted text-xs">{{phoneSubText}}</div>
    <input type="tel" name="phoneInputSelection" class="form-control" #number="ngModel" placeholder="xxxxxxxxxx" required [ngModel]="phone" (ngModelChange)="onPhoneChange($event)" type="tel" maxlength="15" />
    <div *ngIf="(number.invalid || !validPhone) && showErrors">
      <span class="text-danger">Invalid phone number</span>
    </div>
  </div>
</div>
