import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from 'src/app/services/session.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-change-username',
  templateUrl: './change-username.component.html',
  styleUrls: ['./change-username.component.scss']
})
export class ChangeUsernameComponent implements OnInit {

  isLoading = true;
  isSubmitted = false;
  username: string;
  errMsg: string = null;

  constructor(private sessionSvc: SessionService, private userSvc: UserService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.sessionSvc.getToken().subscribe(token => {
      this.username = token.uname;
      this.isLoading = false;
    })
  }

  save(form: NgForm) {
    this.errMsg = null;
    if(form.invalid) {
      this.isSubmitted = true;
      return;
    }


    this.isLoading = true;
    this.userSvc.putUser({ username: this.username }).subscribe({
      next: res => {

        //reload session
        this.sessionSvc.getSession(true).subscribe({
          next: newSess => {
            this.isLoading = false;
            this.router.navigate(['../'], { relativeTo: this.route });
          },
          error: err => {
            this.isLoading = false;
          }
        })

      },
      error: err => {
        this.isLoading = false;
        console.log(err.error.message);
        this.errMsg = err.error.message;
      }
    })
  }

}
