<div *ngIf="!isLoading">

  <div class="mt-5">
    <h4>Hi {{name}}!</h4>
  </div>

  <div class="action-list">
    <button class="btn btn-primary action-button" [routerLink]="['/pass/create']">
      <i class="fa-solid fa-ticket-simple action-list-icon"></i>
      New Pass
    </button>
    <button class="btn btn-primary action-button" [routerLink]="['/callbox/create']">
      <i class="fa-solid fa-square-phone action-list-icon"></i>
      New Callbox
    </button>
    <button class="btn btn-primary action-button" [routerLink]="['/forward/create']">
      <i class="fa-solid fa-address-book action-list-icon"></i>
      New Forward
    </button>
  </div>

  <div class="card-list">
    <div class="card-list-item" [routerLink]="['/pass']">
      <i class="fa-solid fa-ticket-simple card-list-icon"></i>
      <span class="card-list-text">Passes</span>
      <i class="fa-solid fa-chevron-right card-list-next"></i>
    </div>
    <div class="card-list-item" [routerLink]="['/callbox']">
      <i class="fa-solid fa-square-phone card-list-icon"></i>
      <span class="card-list-text">Call Boxes</span>
      <i class="fa-solid fa-chevron-right card-list-next"></i>
    </div>
    <div class="card-list-item" [routerLink]="['/forward']">
      <i class="fa-solid fa-address-book card-list-icon"></i>
      <span class="card-list-text">Forwarding</span>
      <i class="fa-solid fa-chevron-right card-list-next"></i>
    </div>
    <div class="card-list-item" [routerLink]="['/settings']">
      <i class="fa-solid fa-gear card-list-icon"></i>
      <span class="card-list-text">Account</span>
      <i class="fa-solid fa-chevron-right card-list-next"></i>
    </div>
  </div>

</div>

<div *ngIf="isLoading" class="d-flex justify-content-center my-5">
  <app-large-spinner></app-large-spinner>
</div>

<div *ngIf="showSplash && !isLoading">
  <app-dashboard-splash (complete)="onSplashComplete()" [uphone]="uphone"></app-dashboard-splash>
</div>
