<div>

  <div class="mt-4">
    <h1 class="text-center">uPass</h1>
  </div>

  <div>
    <app-setup-stepper [steps]="steps.length" [currentStep]="currentStep" [stepText]="steps[currentStep].title" [stepNextText]="steps[currentStep].next"></app-setup-stepper>
  </div>


  <div *ngIf="!isLoading">
    <!--Steps-->
    <div *ngIf="currentStep == 0">
      <app-setup-subscription (next)="nextStep()" [model]="callboxModel"></app-setup-subscription>
    </div>

    <div *ngIf="currentStep == 1">
      <app-setup-payment (next)="nextStep()" (previous)="prevStep()" [model]="callboxModel"></app-setup-payment>
    </div>

    <div *ngIf="currentStep == 2">
      <app-setup-callbox (next)="nextStep()" (previous)="prevStep()" [model]="callboxModel"></app-setup-callbox>
    </div>

    <div *ngIf="currentStep == 3">
      <app-setup-location (next)="nextStep()" (previous)="prevStep()" [model]="callboxModel"></app-setup-location>
    </div>

    <div *ngIf="currentStep == 4">
      <app-setup-phone (next)="nextStep()" (previous)="prevStep()" [model]="callboxModel"></app-setup-phone>
    </div>

    <div *ngIf="currentStep == 5">
      <app-setup-forward (next)="nextStep()" (previous)="prevStep()" [model]="callboxModel" (commited)="onCommited()"></app-setup-forward>
    </div>

    <div *ngIf="currentStep == 6">
      <app-setup-success (next)="nextStep()" (previous)="prevStep()" [model]="callboxModel"></app-setup-success>
    </div>
  </div>



  <div class="d-flex flex-column align-items-center justify-content-center mb-5" *ngIf="isLoading">
    <h3 class="text-center text-thin my-5">
      Loading...
    </h3>
    <app-large-spinner></app-large-spinner>
  </div>



</div>
