import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from 'src/app/services/session.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  isLoading = false;
  isSubmitted = false;
  newPassword: string;
  newPasswordConf: string;
  errMsg: string = null;

  constructor(private sessionSvc: SessionService, private userSvc: UserService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {

  }

  save(form: NgForm) {
    this.errMsg = null;
    if(form.invalid) {
      this.isSubmitted = true;
      return;
    }

    if(this.newPassword != this.newPasswordConf) {
      this.errMsg = "Passwords do not match";
      return;
    }


    this.isLoading = true;
    this.userSvc.putPassword({ newPassword: this.newPassword, newPasswordConf: this.newPasswordConf}).subscribe({
      next: res => {

        //reload session
        this.sessionSvc.getSession(true).subscribe({
          next: newSess => {
            this.isLoading = false;
            this.router.navigate(['../'], { relativeTo: this.route });
          },
          error: err => {
            this.isLoading = false;
          }
        })

      },
      error: err => {
        this.isLoading = false;
        console.log(err.error.message);
        this.errMsg = err.error.message;
      }
    })
  }

}
