import parsePhoneNumber from 'libphonenumber-js'
import { Pipe, PipeTransform } from '@angular/core';


/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({name: 'phoneFormat'})
export class PhoneFormatPipe implements PipeTransform {
  transform(value: string): string {
    const phoneNumber = parsePhoneNumber(value)

    if(phoneNumber && phoneNumber.isValid()) {
        return phoneNumber.formatInternational();
    } else {
        return "#INVALID"
    }

  }
}
