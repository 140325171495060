import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ForwardService } from 'src/app/services/forward.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-change-phone',
  templateUrl: './change-phone.component.html',
  styleUrls: ['./change-phone.component.scss']
})
export class ChangePhoneComponent implements OnInit {

  @Output() next = new EventEmitter<any>();
  @Output() previous = new EventEmitter<any>();
  @Output() commited = new EventEmitter<any>();

  isSubmitted = false;
  isLoading = false;
  phone: string = "";
  validPhone: boolean;
  @ViewChild('form', { read: NgForm }) form: any;
  code: string;
  codeSent = false;
  validated = false;
  validationFailed = false;

  constructor(private userSvc: UserService, private forwardSvc: ForwardService) { }

  ngOnInit(): void {
    console.log("INIT")
  }

  clickSendCode() {
    this.isSubmitted = true;

    if (!this.form.valid) {
      return;
    }

    if(!this.validPhone) {
      return;
    }

    this.sendCode();
  }

  sendCode() {
    this.isLoading = true;
    this.userSvc.getPhoneVerificationCode({
      phone: this.phone
    }).subscribe(res => {
      //save forward.
      this.isLoading = false;
      this.codeSent = true;
    })
  }

  clickValidate() {
    this.isLoading = true;
    this.validationFailed = false;
    this.userSvc.validatePhoneCode({
      code: this.code
    }).subscribe({
      next: res => {
        this.isLoading = false;
        this.validationFailed = false;
        this.validated = true;
      },
      error: err => {
        this.isLoading = false;
        this.validationFailed = true;
        this.validated = false;
        this.code = "";
      }
    })
  }

  changePhone() {
    //reset original fields
    this.phone = "";
    this.codeSent = false;
    this.isSubmitted = false;
  }

  isValidPhone(value: boolean) {
    this.validPhone = value;
  }
}
