import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-subscription-success',
  templateUrl: './subscription-success.component.html',
  styleUrls: ['./subscription-success.component.scss']
})
export class SubscriptionSuccessComponent implements OnInit {
  @Input() phone: string;

  constructor() { }

  ngOnInit(): void {
  }

}
