import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { interval, Observable, Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { PassService } from 'src/app/services/pass.service';
import { PassViewVM } from "@upass/callbox";
import moment from 'moment';


@Component({
  selector: 'app-guest-pass-view',
  templateUrl: './guest-pass-view.component.html',
  styleUrls: ['./guest-pass-view.component.scss'],
})
export class GuestPassViewComponent implements OnInit, OnDestroy {
  shareId: string = null;
  active = false;
  pass: PassViewVM = null;
  activeTimer: Subscription;
  seconds = 0;
  isLoading = true;
  expiresIn = 30;
  activationId: string = null;

  constructor(private passSvc: PassService, private route: ActivatedRoute) {}

  ngOnInit(): void {


    //get route params
    this.route.queryParamMap.subscribe(params => {
      this.shareId = params.get("shareId");
      this.passSvc.getGuestPass(this.shareId).subscribe({
        next: res => {
          this.pass = res;
          this.isLoading = false;
        },
        error: err => {
          alert("Pass not found.");
        }
      });
    });

  }

  ngOnDestroy(): void {
    this.activeTimer?.unsubscribe();
  }

  onSubmit() {
    this.isLoading = true;
    this.passSvc.activatePass(this.shareId).subscribe(res => {
          this.active = true;
          this.isLoading = false;
          this.activationId = res.activationId;

          this.expiresIn = res.expiresIn;
          //create timer
          this.activeTimer = interval(1000).subscribe(res => {
            console.log(this.seconds);
            if(this.active) {
              this.seconds++;
            }

            if(this.seconds >= this.expiresIn) {
              this.active = false;
              this.seconds = 0;
              this.activeTimer.unsubscribe();
            } else {
              //ping every x seconds
              if(this.seconds % 10 === 0) {
                this.ping();
              }
            }
          })
    });
  }

  clickCancel() {
    this.onCancel();
    this.passSvc.deleteActivation(this.activationId).subscribe({
      next: () => {
        this.activationId = null;
      },
      error: () => {
        console.log("Error deleting activation.")
      }
    })
  }

  onCancel() {
    this.active = false;
    this.seconds = 0;
    this.activeTimer?.unsubscribe();
  }


  ping() {
    this.passSvc.getActivationPing(this.activationId).subscribe(ping => {
      console.log(ping);

      if(ping == null) {
       this.onCancel();
      } else if(ping.isConsumed) {
        this.onCancel();
      } else if(moment(ping.expiresAt).isBefore(moment())) {
        console.log("Ping shows activation is expired");
        this.onCancel();
      }
    });
  }

  openMap() {
    const addr = [this.pass.address1, this.pass.address2, this.pass.city, this.pass.state, this.pass.zip].join("+");
    console.log("Opening map: " + addr);
    window.open("https://www.google.com/maps/search/?api=1&query=" + addr, "_blank");
  }
}
