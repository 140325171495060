import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
// import { NotificationService } from "@app/services/notification.service";

@Component({
   selector: "upass-share-button",
   template: `
      <div *ngIf="supportShare">
         <div>
         <button class="btn btn-link" type="submit" (click)="share()">
            <i class="fa-solid fa-share-nodes"></i>
            <span class="d-block text-sm">Share</span>
          </button>
         </div>
      </div>
      <div *ngIf="!supportShare">
         <div class="d-flex" #container>
            <button ngxClipboard [cbContent]="shareUrl" [container]="container" class="btn btn-primary" (cbOnSuccess)="onCopyLink()">Copy</button>
         </div>
      </div>
   `,
})
export class ShareButtonComponent implements OnInit {
   supportShare = false;
   @Input() shareUrl: string = null;
   @Input() title: string = null;
   @Input() content = "Share";
   @Output() shared = new EventEmitter();

   constructor() {}

   ngOnInit(): void {
      this.supportShare = (window.navigator as any).share != null;
   }

   share() {
      let nav = window.navigator as any;
      nav.share({
         title: document.title,
         text: this.title,
         url: this.shareUrl,
      }).then((data: any) => {
            this.shared.emit();
         },
         (err: any) => {}
      );
   }

   onCopyLink() {
      // this.notifSVC.showSuccess("Link copied.");
   }
}
