import { Component, OnInit } from '@angular/core';
import { CallBoxVM } from '@upass/callbox';
import { CallboxService } from 'src/app/services/callbox.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-callbox-list',
  templateUrl: './callbox-list.component.html',
  styleUrls: ['./callbox-list.component.scss']
})
export class CallboxListComponent implements OnInit {
  items: CallBoxVM[] = [];
  isLoading = true;
  maxCallboxes = 2;
  currCallboxes = 0;
  constructor(private callboxSvc: CallboxService, private sessionSvc: SessionService) { }

  ngOnInit(): void {

    this.callboxSvc.listCallboxes().subscribe(res => {
      this.items = res;
      this.isLoading = false;
    })

    this.sessionSvc.getSession().subscribe(res => {
      let token = this.sessionSvc.decode(res);
      this.maxCallboxes = token.cbm;
      this.currCallboxes = token.cbc;
    });
  }
}
