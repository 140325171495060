<app-page-header [showBack]="false" title="uPass"></app-page-header>

<div class="page">
  <div *ngIf="!isLoading">
    <div *ngIf="!active">
      <!-- pass header-->
      <div class="d-flex flex-column align-items-center mt-3">
        <div>
          <img src="https://cdn3.iconfinder.com/data/icons/landscape-23/135/10-512.png" class="page-header-img" />
        </div>
        <div *ngIf="!pass.isExpired && !pass.isDisabled">You've been been invited</div>
        <div class="text-danger" *ngIf="pass.isExpired">This pass is expired and no longer valid.</div>
        <div class="text-danger" *ngIf="pass.isDisabled && !pass.isExpired">This pass is expired and no longer available.</div>
      </div>

      <!-- pass details-->
      <div class="row justify-content-center mt-3">
        <div class="col-10">
          <app-pass-time-labels [startDt]="pass.startDt" [endDt]="pass.endDt"></app-pass-time-labels>
          <div>
            <span class="text-muted">ADDRESS</span>
            <p>
              {{ pass.address1 }}
              {{ pass.address2 }}
              <br />
              {{ pass.city }} {{ pass.state }}, {{ pass.zip }}
              <br />
              <a class="text-sm" (click)="openMap()">Open Map</a>
            </p>
          </div>
        </div>
      </div>

      <!--Actions-->
      <form>
        <div class="text-sm sm text-muted text-center">
          <small>When you arrive click here to get instructions to open the gate</small>
        </div>

        <div class="d-grid gap-2 mt-3">
          <button class="btn btn-lg btn-primary" type="button" (click)="onSubmit()" [disabled]="pass.isExpired || pass.isDisabled">
            I'm here
          </button>
        </div>
      </form>
    </div>
    <div *ngIf="active">
      <h3 class="wait-text">Waiting for callbox</h3>

      <div class="active-content">
        <!-- <img src="/assets/img/ringingphone-icon.svg" class="page-header-img" /> -->
        <div class="phone-wrapper">
          <div class="ring">
            <div class="coccoc-alo-phone coccoc-alo-green coccoc-alo-show">
              <div class="coccoc-alo-ph-circle"></div>
              <div class="coccoc-alo-ph-circle-fill"></div>
              <div class="coccoc-alo-ph-img-circle">
                <img src="/assets/img/ringingphone-icon.svg" />
              </div>
            </div>
          </div>
        </div>
        <h3 class="text-center">
          <span class="text-bold">Dial</span>
          <br />
          <span>{{pass.callboxCode}}</span>
        </h3>
        <div class="dot-pulse"></div>
        <div class="subtext">
          Call box is active. Once you dial the callbox, upass will accept your call and let you in. Call box will stay
          active for {{expiresIn}} seconds. Deactivates in {{expiresIn - seconds}} seconds...
        </div>
        <button class="btn btn-lg btn-outline-primary action-btn" type="button" (click)="clickCancel()">
          Cancel
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="isLoading" class="d-flex">
    <app-page-loader></app-page-loader>
  </div>
</div>
