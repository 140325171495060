import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CallboxService } from 'src/app/services/callbox.service';
import { ForwardService } from 'src/app/services/forward.service';
import { SetupModel } from '../setup.model';

@Component({
  selector: 'app-setup-forward',
  templateUrl: './setup-forward.component.html',
  styleUrls: ['./setup-forward.component.scss']
})
export class SetupForwardComponent implements OnInit {
  @Output() next = new EventEmitter<any>();
  @Output() previous = new EventEmitter<any>();
  @Output() commited = new EventEmitter<any>();
  @Input() model: SetupModel;
  isSubmitted = false;
  isLoading = false;
  phone: string = "";
  validPhone: boolean;
  @ViewChild('form', { read: NgForm }) form: any;

  constructor(private callboxSvc: CallboxService, private forwardSvc: ForwardService) { }

  ngOnInit(): void {
  }

  clickNext() {
    this.isSubmitted = true;

    if (!this.form.valid) {
      return;
    }

    if(!this.validPhone) {
      return;
    }

    //save forward
    this.model.forward = this.phone;

    this.callboxSvc.createCallBox(this.model).subscribe({
      next: res => {
        this.forwardSvc.createForward({ forwardNumber: this.model.forward }).subscribe({
          next: fwRes => {
            this.isLoading = false;
            this.commited.emit();
            this.commited.complete();

          },
          error: err => {
            alert("Error creating forward");
          }
        })
      },
      error: err => {
        alert("Error creating callbox records");
      }
    });

    this.next.emit();
  }

  clickPrevious() {
    this.previous.emit();
  }

  isValidPhone(value: boolean) {
    this.validPhone = value;
  }

}
