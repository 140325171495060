<div>

  <app-page-header title="Logged Out" [showBack]="false"></app-page-header>


  <div class="page">
    <h4 class="text-center my-5">You've been logged out</h4>
    <div>
      <button class="btn btn-outline-primary btn-block" (click)="clickLogin()">
        Log in
      </button>
    </div>
  </div>
</div>
