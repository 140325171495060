import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
   selector: "app-card-number",
   template: `
       <span>
          <i class="fa-brands fa-cc-visa" *ngIf="type === 'visa'"></i>
          <i class="fa-brands fa-cc-mastercard" *ngIf="type === 'mastercard'"></i>
          <i class="fa-brands fa-cc-stripe" *ngIf="type === 'stripe'"></i>
          <i class="fa-brands fa-cc-discover" *ngIf="type === 'discover'"></i>
          <i class="fa-brands fa-cc-paypal" *ngIf="type === 'paypal'"></i>
          <i class="fa-brands fa-cc-jcp" *ngIf="type === 'jcp'"></i>
          <i class="fa-brands fa-cc-apple-pay" *ngIf="type === 'apple-pay'"></i>
          <i class="fa-brands fa-cc-amazon-pay" *ngIf="type === 'amazon-pay'"></i>
          <i class="fa-brands fa-cc-amex" *ngIf="type === 'amex'"></i>
        </span>
               &#x2022;&#x2022;&#x2022;&#x2022; &#x2022;&#x2022;&#x2022;&#x2022; &#x2022;&#x2022;&#x2022;&#x2022; {{last4}}
   `,
})
export class CardNumberComponent implements OnInit {
   @Input() last4: string = null;
   @Input() type: string = null;

   constructor() {}

   ngOnInit(): void {}
}
