import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { getCountriesFmt } from '../country-codes.models';

@Component({
  selector: 'app-country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.scss']
})
export class CountrySelectComponent implements OnInit, OnChanges {
  countries = getCountriesFmt();

  @Input() country: string = "+1";
  @Output() countryChange = new EventEmitter<string>();

  constructor() { }


  ngOnChanges(changes: SimpleChanges): void {

  }

  ngOnInit(): void {
    this.country = "+1";
    this.countryChange.next(this.country);
  }

  onChange(value: string) {
    this.country = value;

    this.countryChange.next(value);
  }
}
