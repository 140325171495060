import { CacheSubject } from '@upass/ui-common';
import { Injectable } from '@angular/core';
import { CallBoxVM, CreateCallBox, PutCallBox } from '@upass/callbox';
import { ReplaySubject, tap } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CallboxService {
  callboxCache: CacheSubject<CallBoxVM[]> = null;

  constructor(private api: ApiService) { }

  /** Create a new callbox */
  createCallBox(params: CreateCallBox) {
    return this.api.post<any>("/callbox", params).pipe(tap(p => this.invalidateCache()));
  }

  /** Returns list of callboxes */
  listCallboxes() {
    if(this.callboxCache == null) {
      this.callboxCache = new CacheSubject(1);

      this.api.get<CallBoxVM[]>("/callbox").subscribe(res => {
        this.callboxCache.set(res);
      })

    }

    return this.callboxCache.asObservable();
  }

  /** Get a callbox */
  getCallBox(callboxId: string) {
    return this.api.get<CallBoxVM>(`/callbox/${callboxId}`);
  }

   /** Get a callbox */
  putCallBox(callboxId: string, callbox: PutCallBox) {
    return this.api.put<CallBoxVM>(`/callbox/${callboxId}`, callbox);
  }

   /** Delete a new callbox */
   deleteCallBox(callboxId: string) {
    return this.api.delete<CallBoxVM>(`/callbox/${callboxId}`).pipe(tap(p => this.invalidateCache()));
  }

  invalidateCache() {
    this.callboxCache = null;
  }
}
