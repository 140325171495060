
<!--page 1-->
<div class="fade-in" *ngIf="!isNext">

  <app-page-header title="Edit CallBox" [useBrowserBack]="true"></app-page-header>
  <div class="page">
    <div *ngIf="!isLoading">
      <!--header-->
      <div>
        <div class="d-flex justify-content-center">
          <img src="/assets/img/callbox-icon.svg" class="header-img grow-in" />
        </div>
        <p class="text-muted text-center">Update your callbox.</p>
      </div>
      <!--body-->
      <form #callboxForm="ngForm" name="callbox" (submit)="clickNext(callboxForm)">
        <div>
          <app-phone-input (isValidPhone)="isValidPhone($event)" name="phoneSelection" [(value)]="model.callboxPhone" [showErrors]="isSubmittedCallBox"></app-phone-input>
        </div>
        <div class="mb-3">
          <label>Dial Key</label>
          <div class="text-muted text-xs">This is the key you press on your phone to let the guest in</div>
          <select class="form-control" required name="dialKey" [(ngModel)]="model.dialKey">
            <option>0</option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
            <option>6</option>
            <option>7</option>
            <option>8</option>
            <option>9</option>
            <option>#</option>
            <option>*</option>
          </select>
        </div>
        <div class="mb-3">
          <label>Gate Code</label>
          <div class="text-muted text-xs">This is the code your guests use at the callbox</div>
          <input type="text" name="gateCode" class="form-control" placeholder="#123" required [(ngModel)]="model.callboxCode"/>
        </div>
        <div class="text-danger text-sm" *ngIf="isSubmittedCallBox && callboxForm.form.invalid">
          Please fill out all fields
        </div>
        <div class="d-grid gap-2">
          <button class="btn btn-primary" type="submit">
            Next
            <i class="fa-solid fa-chevron-right"></i>
          </button>
        </div>

      </form>
    </div>
    <div *ngIf="isLoading">
      <app-page-loader></app-page-loader>
    </div>
  </div>
</div>


<!--page 2-->
<div class="fade-in" *ngIf="isNext">

  <app-page-header title="Edit CallBox" (clickedBack)="clickBack()" [preventBack]="true"></app-page-header>
  <div class="page">
    <div *ngIf="!isLoading">
      <!--header-->
      <div>
        <div class="d-flex justify-content-center">
          <img src="/assets/img/location-icon.svg" class="header-img grow-in" />
        </div>
        <h3 class="text-center">Location</h3>
        <p class="text-muted text-center">Update your callbox address. We'll show this on your guests pass for directions.</p>
      </div>
      <!--body-->
      <form #form="ngForm" name="callbox" (submit)="save(form)">
        <div class="mb-3">
          <label class="form-label">Name</label>
          <input type="text" name="name" class="form-control" placeholder="Ex. My Beach House" required [(ngModel)]="model.name"/>
        </div>
        <div class="mb-3">
          <label class="form-label">Address</label>
          <input type="text" name="address" class="form-control" required [(ngModel)]="model.address1" />
        </div>
        <div class="mb-3">
          <label class="form-label">Address Line 2</label>
          <input type="text" name="address2" class="form-control" [(ngModel)]="model.address2"  />
        </div>
        <div class="mb-3">
          <label class="form-label">City</label>
          <input type="text" name="city" class="form-control" required [(ngModel)]="model.city"  />
        </div>
        <div class="row">
          <div class="col mb-3">
            <label class="form-label">State</label>
            <input type="text" name="state" class="form-control" required [(ngModel)]="model.state"/>
          </div>
          <div class="col mb-3">
            <label class="form-label">Zip</label>
            <input type="text" name="zip" class="form-control" required [(ngModel)]="model.zip"/>
          </div>
        </div>
        <div class="text-danger text-sm" *ngIf="isSubmittedLocation && form.form.invalid">
          Please fill out all fields
        </div>
        <div>
          <button class="btn btn-primary btn-block" type="submit">Save</button>
        </div>
      </form>
    </div>


    <div *ngIf="isLoading">
      <app-page-loader></app-page-loader>
    </div>
  </div>
</div>


