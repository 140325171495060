
<!--page 1-->
<div class="fade-in">

  <app-page-header title="Create a Forward" [useBrowserBack]="true"></app-page-header>
  <div class="page">
    <div *ngIf="!isLoading">

      <!--header-->
      <div>
        <div class="d-flex justify-content-center">
          <img src="/assets/img/phone-icon.svg" class="header-img grow-in" />
        </div>
        <p class="text-muted text-center">Create a forward number.</p>
      </div>

      <!--body-->
      <form #callboxForm="ngForm" name="callbox" (submit)="submit(callboxForm)">
        <div>
          <app-phone-input (isValidPhone)="isValidPhone($event)" name="phone" [(value)]="phone" [showErrors]="isSubmitted"></app-phone-input>
        </div>

        <div class="text-danger text-sm" *ngIf="isSubmitted && callboxForm.form.invalid">
          Please fill out all fields
        </div>

        <div class="d-flex flex-column align-items-center justify-content-center">
          <button class="btn btn-block btn-primary" type="submit">
            Create
          </button>
        </div>

      </form>
    </div>
    <div *ngIf="isLoading">
      <app-page-loader></app-page-loader>
    </div>
  </div>
</div>

