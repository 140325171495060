import { SessionToken, SubscriptionStatus } from '@upass/callbox';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { concatMap, interval, Subscription } from 'rxjs';
import { CallboxService } from 'src/app/services/callbox.service';
import { SessionService } from 'src/app/services/session.service';
import { SetupModel } from '../setup.model';

@Component({
  selector: 'app-setup-callbox',
  templateUrl: './setup-callbox.component.html',
  styleUrls: ['./setup-callbox.component.scss'],
})
export class SetupCallboxComponent implements OnInit, OnDestroy {
  @Output() next = new EventEmitter<any>();
  @Output() previous = new EventEmitter<any>();
  @Input() model: SetupModel;
  @ViewChild('form', { read: NgForm }) form: any;
  isSubmitted = false;
  validPhone = false;
  checkInterval: Subscription;
  isLoading = true;
  phone = "";
  sessionCount = 0;


  constructor(private router: Router, private sessionService: SessionService, private callboxSvc: CallboxService) { }


  ngOnInit(): void {
    console.log("INIT > MODEL: ", this.model);



    this.sessionService.getToken().subscribe(token => {
      if (token.sst == SubscriptionStatus.SUBSCRIBED) {
        this.handleSubscription(token);
      } else {
        this.startInterval();
      }
    })

  }

  ngOnDestroy(): void {
    if(this.checkInterval) {
      this.checkInterval.unsubscribe();
    }
  }

  startInterval() {
    //get session until subscription shows as subscribed
    this.checkInterval = interval(3000).subscribe(() => {
      this.sessionService.getSession(true).subscribe({
        next: (token) => {
          let decoded = this.sessionService.decode(token);
          if (decoded.sst !== SubscriptionStatus.SUBSCRIBED) {
            console.log("NOT SUBSCRIBED YET");

            if(this.sessionCount == 10) {
              this.checkInterval.unsubscribe();
              alert("Taking too long, there might have been an error. Please verify your card was not charged and contact support.");
              this.clickPrevious();
            }

            this.sessionCount++;
            return;
          }

          this.checkInterval.unsubscribe();
          this.handleSubscription(decoded);
        },
        error: () => {
          console.log("Error getting session");
          this.checkInterval.unsubscribe();
        }
      });

    });
  }

  clickNext() {
    console.log(this.form);
    this.isSubmitted = true;


    if (!this.form.valid || !this.validPhone) {
      return;
    }

    this.next.emit();
  }

  clickPrevious() {
    this.previous.emit();
  }

  isValidPhone(value: boolean) {
    this.validPhone = value;
  }

  handleSubscription(decoded: SessionToken) {
    this.phone = decoded.upn;
    this.model.isPaid = true;
    this.isLoading = false;
  }
}
