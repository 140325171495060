<div>

  <app-page-header title="My Subscription"></app-page-header>

  <div class="page">

    <div *ngIf="!isLoading">
      <div class="row justify-content-center">
        <div class="col-5">
          <app-progress-circle [value]="currCallboxes" [maxValue]="maxCallboxes" text="Callboxes"></app-progress-circle>
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="flex-grow-1">
          <a class="text-sm" *ngIf="currCallboxes >= maxCallboxes && !isCancelled">Upgrade your subscripton to get more callboxes</a>
          <div *ngIf="isCancelled">
            <div class="py-2 text-center text-danger">
              Your subscription has been cancelled and will be deactivated on
              <span class="text-decoration-underline">{{subEndsAt}}</span>
            </div>
          </div>

        </div>
      </div>

      <div class="your-plan">
        <div class="flex-grow-1">
          <div class="sub-text">Your Plan</div>
          <div class="plan-name">{{product.name}}</div>
        </div>
        <div>
          <span class="plan-price">${{product.price / 100 | number : '1.2-2'}}</span>
          <br />
          <span class="plan-price-sub">/month</span>
        </div>
      </div>

      <div class="product-tiles">
        <div class="product-tile">
          <i class="fa-solid fa-square-phone"></i>
          <span>{{product.callboxes}}</span>
          <span class="product-label">callboxes</span>
        </div>
        <div class="product-tile">
          <i class="fa-solid fa-address-book"></i>
          <span>{{product.forwards}}</span>
          <span class="product-label">forward #s</span>
        </div>
      </div>

      <div class="product-description">

        <span class="text-muted">Includes:</span>
        <ul>
          <li>Up to {{product.callboxes}} callboxes</li>
          <li>Forwarding calls to up to {{product.forwards}} numbers</li>
        </ul>
      </div>

      <div class="d-flex flex-column align-items-center">
        <div *ngIf="!isCancelled" class="mb-2">
          <a [routerLink]="['cancel']" class="btn-link text-sm py-2">Cancel Subscription</a>
        </div>

        <button class="btn btn-block btn-outline-primary" *ngIf="!isCancelled" [routerLink]="['change']">
          Change Plan
        </button>

        <button class="btn btn-block btn-outline-primary" *ngIf="isCancelled"  (click)="reactivate()">
          Reactivate Plan
        </button>
      </div>
    </div>

    <div *ngIf="isLoading">
      <app-page-loader></app-page-loader>
    </div>



  </div>
</div>
