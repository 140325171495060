import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { CreateCallBox } from '@upass/callbox';
import { CallboxService } from 'src/app/services/callbox.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-callbox-create',
  templateUrl: './callbox-create.component.html',
  styleUrls: ['./callbox-create.component.scss']
})
export class CallboxCreateComponent implements OnInit {
  isLoading = false;
  isCallboxSubmitted = false;
  isLocationSubmitted = false;
  isNext = false;
  model: CreateCallBox =   {
    name: "",
    callboxPhone: "",
    callboxCode: "",
    dialKey: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
  };
  validPhone = false;


  constructor(private callboxSvc: CallboxService, private sessionSvc: SessionService, private router: Router) { }

  ngOnInit(): void {
  }

  clickedBack() {
    this.isNext = false;
  }
  clickNext(form: NgForm) {
    this.isCallboxSubmitted = true;

    if(form.invalid) {
      return;
    }

    this.isNext = true;
  }

  submit(form: NgForm) {
    this.isLocationSubmitted = true;

    if(form.invalid) {
      return;
    }

    if(!this.isValidPhone) {

    }

    //create callbox
    this.isLoading = true;
    this.callboxSvc.createCallBox(this.model).subscribe(res => {
      this.sessionSvc.getSession(true).subscribe(ses => {
        this.isLoading = false;
        this.router.navigate(["callbox", res.callboxId])
      })
    });

  }

  isValidPhone(value: boolean) {
    this.validPhone = value;
  }
}
