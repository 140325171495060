import { SubscriptionCancelRequest, SubscriptionStatus } from '@upass/callbox';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from 'src/app/services/session.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { delay, of, Subscription } from 'rxjs';
import moment from 'moment';

const options = [
  {
    id: 0,
    option: "It's too costly",
    question: "Additional Comments",
    hasQuestion: false
  },
  {
    id: 1,
    option: "I found another product",
    question: "What does this other product have that uPass does not offer?",
    hasQuestion: true
  },
  {
    id: 2,
    option: "I don't use it often enough",
    question: "Is there anything we could do for you to use uPass more?",
    hasQuestion: true
  },
  {
    id: 3,
    option: "Other",
    question: "Additional Comments",
    hasQuestion: true
  },
]


@Component({
  selector: 'app-subscription-cancel',
  templateUrl: './subscription-cancel.component.html',
  styleUrls: ['./subscription-cancel.component.scss']
})
export class SubscriptionCancelComponent implements OnInit {
  isLoading = 2;
  readonly options = options;
  option: any = null;
  comments: string = null;
  requiredError = false;
  planEndDate: Date;

  constructor(private router: Router, private route: ActivatedRoute, private subSvc: SubscriptionService, private sessionSvc: SessionService) { }


  ngOnInit(): void {
    console.log("NG INIT CANCELL")
    this.sessionSvc.getToken().subscribe(ses => {
      this.planEndDate = moment(ses.sxp).toDate();
      if(ses.sst !== SubscriptionStatus.SUBSCRIBED) {
        this.router.navigate(["../"], { relativeTo: this.route })
      }

      this.isLoading--;
    });

    //add 1 second delay to slow down cancellation
    of(1).pipe(delay(1500)).subscribe(() => {
      this.isLoading--;
     })
  }

  clickCancel() {
    if(!this.option) {
      this.requiredError = true;
      return;
    }

    if(confirm("Are you sure you want to cancel your subscription?")) {
      const model = {
        reason: this.option.option,
        comments: this.comments
      } as SubscriptionCancelRequest;

      this.isLoading = 1;
      this.subSvc.cancelSubscription(model).subscribe(res => {
        this.sessionSvc.getSession(true).subscribe(res => {
          this.router.navigate(["../"], { relativeTo: this.route })
        });
      });
    }
  }

}
