
<!--page 1-->
<div class="fade-in">

  <app-page-header title="Change Email" [useBrowserBack]="true"></app-page-header>
  <div class="page">
    <div *ngIf="!isLoading">
      <!--body-->
      <form #callboxForm="ngForm" name="callbox" (submit)="save(callboxForm)">

        <div class="mb-3">
          <label class="form-label">New Email</label>
          <input class="form-control" type="email" required name="email" #input="ngModel" [email]="true" [(ngModel)]="email" />
          <div>
            <div class="text-danger text-sm" *ngIf="isSubmitted && input.errors?.['required']">
              Please fill out all fields
            </div>
            <div class="text-danger text-sm" *ngIf="isSubmitted && input.errors?.['email']">
              Enter a valid email
            </div>
            <div class="text-danger text-sm" *ngIf="errMsg != null">
              {{errMsg}}
            </div>
          </div>


        </div>

        <div>
          <button class="btn btn-primary btn-block">Save</button>
        </div>
      </form>
    </div>
    <div *ngIf="isLoading">
      <app-page-loader></app-page-loader>
    </div>
  </div>
</div>

