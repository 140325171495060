import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { SessionService } from './session.service';
import { mergeMap } from 'rxjs';

/**
 * Service to wrap api calls
 */
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  host = environment.api_url;

  constructor(private sessionSvc: SessionService, private http: HttpClient) {}

  post<T>(path: string, payload: any) {
    return this.sessionSvc.getSession().pipe(
      mergeMap(token => {
        return this.http.post<T>(`${this.host}${path}`, payload, {
          headers: this.getHeaders(token)
        });
      })
    );
  }

  get<T>(path: string, query?: any) {
    return this.sessionSvc.getSession().pipe(
      mergeMap(token => {
        return this.http.get<T>(`${this.host}${path}`, {
          params: query,
          headers: this.getHeaders(token),
        });
      })
    );
  }

  delete<T>(path: string, query?: any) {
    return this.sessionSvc.getSession().pipe(
      mergeMap(token => {
        return this.http.delete<T>(`${this.host}${path}`, {
          params: query,
          headers: this.getHeaders(token)
        });
      })
    );
  }

  put<T>(path: string, payload: any) {
    return this.sessionSvc.getSession().pipe(
      mergeMap(token => {
        return this.http.put<T>(`${this.host}${path}`, payload, {
          headers: this.getHeaders(token)
        });
      })
    );
  }

  private getHeaders(token?: string) {
    if(token) {
      return {
        "xup-session": token
      }
    }
    return {};
  }
}
