import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClipboardModule } from 'ngx-clipboard';
import { UPassUICommonModule } from "@upass/ui-common";
import { AuthModule } from '@auth0/auth0-angular';
import { DragScrollModule } from 'ngx-drag-scroll';
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatSelectModule } from '@angular/material/select';


// Import the module from the SDK
import { GuestPassViewComponent } from './guest/guest-pass-view/guest-pass-view.component';
import { PassCreateComponent } from './pass/pass-create/pass-create.component';
import { PassListComponent } from './pass/pass-list/pass-list.component';
import { PassViewComponent } from './pass/pass-view/pass-view.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { LoginComponent } from './auth/login/login.component';
import { AuthRedirectComponent } from './auth/auth-redirect/auth-redirect.component';
import { AuthInterceptor } from './common/auth.interceptor';
import { DateTypeDirective } from './common/datetype.directive';
import { ShareButtonComponent } from './common/share-button.component';
import { MomentModule } from 'ngx-moment';

import { environment } from 'src/environments/environment';
import { PassTimeLabelsComponent } from './common/pass/pass-time-labels/pass-time-labels.component';
import { SetupContainerComponent } from './setup/setup-container/setup-container.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BackButtonComponent } from './common/back-button/back-button.component';
import { SetupStepperComponent } from './setup/setup-stepper/setup-stepper.component';
import { SetupSubscriptionComponent } from './setup/setup-subscription/setup-subscription.component';
import { SetupLocationComponent } from './setup/setup-location/setup-location.component';
import { SetupStepButtonsComponent } from './setup/setup-step-buttons/setup-step-buttons.component';
import { SetupCallboxComponent } from './setup/setup-callbox/setup-callbox.component';
import { SetupPaymentComponent } from './setup/setup-payment/setup-payment.component';
import { SetupSuccessComponent } from './setup/setup-success/setup-success.component';
import { PageLoaderComponent } from './common/page-loader/page-loader.component';
import { CallboxListComponent } from './callbox/callbox-list/callbox-list.component';
import { CallboxViewComponent } from './callbox/callbox-view/callbox-view.component';
import { PageHeaderComponent } from './common/page-header/page-header.component';
import { CallboxCreateComponent } from './callbox/callbox-create/callbox-create.component';
import { SettingsComponent } from './settings/settings.component';
import { SubscriptionComponent } from './settings/subscription/subscription.component';
import { ProgressCircleComponent } from './common/progress-circle/progress-circle.component';
import { CallboxEditComponent } from './callbox/callbox-edit/callbox-edit.component';
import { ForwardListComponent } from './forward/forward-list/forward-list.component';
import { ForwardEditComponent } from './forward/forward-edit/forward-edit.component';
import { ForwardCreateComponent } from './forward/forward-create/forward-create.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PhoneInputComponent } from './common/phone-input/phone-input.component';
import { CountrySelectComponent } from './common/country-select/country-select.component';
import { DashboardSplashComponent } from './dashboard/dashboard-splash/dashboard-splash.component';
import { PhoneFormatPipe } from './common/phone.pipe';
import { BillingHistoryComponent } from './settings/billing-history/billing-history.component';
import { LargeSpinnerComponent } from './common/large-spinner/large-spinner.component';
import { PaymentMethodComponent } from './settings/payment-method/payment-method.component';
import { CardNumberComponent } from './common/card-number.component';
import { SubscriptionCancelComponent } from './settings/subscription/subscription-cancel/subscription-cancel.component';
import { SubscriptionChangeComponent } from './settings/subscription/subscription-change/subscription-change.component';
import { SubscriptionChangeCapacityComponent } from './settings/subscription/subscription-change/subscription-change-capacity/subscription-change-capacity.component';
import { SubscriptionChangeConfirmComponent } from './settings/subscription/subscription-change/subscription-change-confirm/subscription-change-confirm.component';
import { ResubscribeComponent } from './resubscribe/resubscribe.component';
import { SubscriptionChangeSelectComponent } from './settings/subscription/subscription-change/subscription-change-select/subscription-change-select.component';
import { ResubscribeSelectComponent } from './resubscribe/resubscribe-select/resubscribe-select.component';
import { ResubscribePaymentComponent } from './resubscribe/resubscribe-payment/resubscribe-payment.component';
import { SubscriptionSuccessComponent } from './common/subscription-success/subscription-success.component';
import { ResubscribeSuccessComponent } from './resubscribe/resubscribe-success/resubscribe-success.component';
import { PassEditComponent } from './pass/pass-edit/pass-edit.component';
import { SetupForwardComponent } from './setup/setup-forward/setup-forward.component';
import { NotificationsComponent } from './settings/notifications/notifications.component';
import { ProfileComponent } from './settings/profile/profile.component';
import { SetupPhoneComponent } from './setup/setup-phone/setup-phone.component';
import { ChangePhoneComponent } from './settings/profile/change-phone/change-phone.component';
import { ChangeEmailComponent } from './settings/profile/change-email/change-email.component';
import { ChangeUsernameComponent } from './settings/profile/change-username/change-username.component';
import { ChangeNameComponent } from './settings/profile/change-name/change-name.component';
import { ChangePasswordComponent } from './settings/profile/change-password/change-password.component';


@NgModule({
  declarations: [
    AppComponent,
    GuestPassViewComponent,
    PassCreateComponent,
    PassListComponent,
    LogoutComponent,
    AuthRedirectComponent,
    LoginComponent,
    DateTypeDirective,
    PassViewComponent,
    ShareButtonComponent,
    PassTimeLabelsComponent,
    SetupContainerComponent,
    DashboardComponent,
    BackButtonComponent,
    SetupStepperComponent,
    SetupSubscriptionComponent,
    SetupLocationComponent,
    SetupStepButtonsComponent,
    SetupCallboxComponent,
    SetupPaymentComponent,
    SetupSuccessComponent,
    PageLoaderComponent,
    CallboxListComponent,
    CallboxViewComponent,
    PageHeaderComponent,
    CallboxCreateComponent,
    SettingsComponent,
    SubscriptionComponent,
    ProgressCircleComponent,
    CallboxEditComponent,
    ForwardListComponent,
    ForwardEditComponent,
    ForwardCreateComponent,
    PhoneInputComponent,
    CountrySelectComponent,
    DashboardSplashComponent,
    PhoneFormatPipe,
    BillingHistoryComponent,
    LargeSpinnerComponent,
    PaymentMethodComponent,
    CardNumberComponent,
    SubscriptionCancelComponent,
    SubscriptionChangeComponent,
    SubscriptionChangeCapacityComponent,
    SubscriptionChangeConfirmComponent,
    ResubscribeComponent,
    SubscriptionChangeSelectComponent,
    ResubscribeSelectComponent,
    ResubscribePaymentComponent,
    SubscriptionSuccessComponent,
    ResubscribeSuccessComponent,
    PassEditComponent,
    SetupForwardComponent,
    NotificationsComponent,
    ProfileComponent,
    SetupPhoneComponent,
    ChangePhoneComponent,
    ChangeEmailComponent,
    ChangeUsernameComponent,
    ChangeNameComponent,
    ChangePasswordComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
    UPassUICommonModule,
    ClipboardModule,
    DragScrollModule,
    DragDropModule,
    MatSelectModule,
    AuthModule.forRoot({
      domain: environment.auth_domain,
      clientId: environment.auth_clientId,
      redirectUri: environment.auth_redirect_url,
      cacheLocation: "localstorage",
      audience: environment.auth_audience
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
