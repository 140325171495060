<div>

  <app-page-header title="Settings"></app-page-header>

  <div class="page">
    <div *ngIf="!isLoading">


      <div class="user-header mb-3">
          <div class="text-bold">
            <h5 class="mb-0">{{username}}</h5>
          </div>
          <div class="text-muted mb-1" *ngIf="isNative">
            Password: **********
          </div>
          <div class="text-sm mb-1" *ngIf="!isNative">
            Authenticated with: {{authProvider}}
          </div>
          <div>
            <span class="uphone-container">uPass Number: {{phone | phoneFormat}}</span>
          </div>
      </div>

      <!-- <div class="mb-3" *ngIf="phone" class="">
        <div class="text-center">Your upass phone number</div>
        <div class="d-flex justify-content-center">

        </div>
      </div> -->

      <div class="menu-list mb-3">
        <div class="menu-item" [routerLink]="['profile']">
          <span class="menu-text">Personal Information</span>
          <i class="fa-solid fa-chevron-right card-list-next"></i>
        </div>
        <div class="menu-item" [routerLink]="['notifications']">
          <span class="menu-text">Notifications</span>
          <i class="fa-solid fa-chevron-right card-list-next"></i>
        </div>
        <div class="menu-item" [routerLink]="['subscription']">
          <span class="menu-text">Manage Subscription</span>
          <i class="fa-solid fa-chevron-right card-list-next"></i>
        </div>
        <div class="menu-item" [routerLink]="['billing']">
          <span class="menu-text">Billing History</span>
          <i class="fa-solid fa-chevron-right card-list-next"></i>
        </div>
        <div class="menu-item" [routerLink]="['payment-method']">
          <span class="menu-text">Payment Methods</span>
          <i class="fa-solid fa-chevron-right card-list-next"></i>
        </div>
        <!-- <div class="menu-item">
          <span class="menu-text">Help</span>
          <i class="fa-solid fa-chevron-right card-list-next"></i>
        </div> -->
        <!-- <div class="menu-item">About</div> -->
      </div>

      <div>
        <button class="btn btn-outline-primary btn-block" [routerLink]="['/auth', 'logout']">
          Logout
        </button>
      </div>
    </div>
    <div *ngIf="isLoading" class="d-flex justify-content-center">
      <upass-spinner></upass-spinner>
    </div>

  </div>
</div>
