import { Component, OnInit } from '@angular/core';
import { PassListItem, PassListFilter, PassListQueryParams } from '@upass/callbox';
import { PassService } from 'src/app/services/pass.service';

@Component({
  selector: 'app-pass-list',
  templateUrl: './pass-list.component.html',
  styleUrls: ['./pass-list.component.scss']
})
export class PassListComponent implements OnInit {
  items: PassListItem[] = [];
  isLoading = true;
  filter = "active";
  nextKey: string = null;

  constructor(private passSvc: PassService) { }

  ngOnInit(): void {

    this.isLoading = true;
    this.passSvc.listPass({
      filter: this.filter
    }).subscribe(res => {
      this.items = res.passes;
      this.isLoading = false;
      this.nextKey = res.nextKey;
    })
  }

  clickFilter(filterVal: string) {
    if(this.filter == filterVal) {
      return;
    }

    this.nextKey = null;
    this.filter = filterVal;
    this.loadList();
  }


  loadList() {
    const opts = {
      filter: this.filter
    } as PassListQueryParams;

    if(this.nextKey) {
      opts.nextKey = this.nextKey;
    }

    this.isLoading = true;
    this.passSvc.listPass(opts).subscribe(res => {
      this.items = res.passes;
      this.nextKey = res.nextKey;
      this.isLoading = false;
    })
  }
}
