import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  query,
  sequence,
  group
} from '@angular/animations';
import { interval, Subscription } from "rxjs";

const MAX_STEPS = 3;

@HostBinding('')
@Component({
  selector: 'app-dashboard-splash',
  templateUrl: './dashboard-splash.component.html',
  styleUrls: ['./dashboard-splash.component.scss'],
  animations: [
    // animation triggers go here
    trigger("enterExit", [
      transition(':enter', [
        sequence([
          group([
            query('.content-header', style({opacity: 0, transform: 'translateY(-100px)'})),
            query('.image-content', style({opacity: 0, transform: 'translateX(-100px)'})),
            query('.content-action', style({opacity: 0})),
          ]),
          sequence([
            sequence([
              query('.content-header', [
                style({opacity: 0, transform: 'translateY(-100px)'}),
                animate("0.33s ease-in")
              ]),
              query('.image-content', [
                style({opacity: 0, transform: 'translateX(-100px)'}),
                animate("0.33s ease-in")
              ]),
              query('.content-action', [
                style({opacity: 0}),
                animate("0.33s ease-in")
              ]),
            ]),
          ], { delay: "0.5s"}),
        ]),
      ]),
      transition(':leave', [
        // style({ position: 'absolute' }),
        group([
          query('.content-header', [
            animate("0.33s ease-in"),
            style({opacity: 0, transform: 'translateX(-100px)'}),
          ]),
          query('.image-content', [
            animate("0.33s ease-in"),
            style({opacity: 0, transform: 'translateX(100px)'}),
          ]),
          query('.content-action', [
            animate("0.33s ease-in"),
            style({opacity: 0, transform: 'translateX(-100px)'}),
          ]),
        ])
      ])
    ]),
    trigger("exitBg", [
      transition(":enter", [
        style({ opacity: 0}),
        animate('0.5s'),
      ]),
      transition(":leave", [
        style({ opacity: 0.5}),
        animate('0.33s'),
      ])
    ])
  ]
})
export class DashboardSplashComponent implements OnInit {
  @Input() uphone = "" //TODO: FORMAT WITH GOOGLE
  currStep = 0;
  stepperSteps = Array.from(Array(MAX_STEPS).keys())
  imagesLoaded = 0;
  isImagesLoaded = false;
  accepted = false;
  downpage = false;
  downtime: Subscription = null;
  readonly MAX_STEPS = MAX_STEPS;
  readonly LAST_STEP = MAX_STEPS - 1;
  readonly DONE_STEP = MAX_STEPS;

  @Output() complete = new EventEmitter<null>();

  constructor() { }

  ngOnInit(): void {

  }

  next() {
    if(this.currStep == this.LAST_STEP) {
      if(!this.accepted) {
        return;
      }
    }

    this.currStep++;
    this.downpage = true;
    this.downtime = interval(330).subscribe(res => {
      this.downpage = false;
      this.downtime.unsubscribe();
    })

    if(this.currStep == this.DONE_STEP) {
      this.complete.next(null);
      this.complete.complete();
    }
  }

  imageLoaded() {
    this.imagesLoaded++;
    this.isImagesLoaded = this.imagesLoaded == this.MAX_STEPS;
  }

}
