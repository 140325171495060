import { Injectable } from '@angular/core';
import { delay, map, Observable, of, ReplaySubject, Subject, tap } from 'rxjs';
import { CacheSubject } from '@upass/ui-common';
import jwt_decode from "jwt-decode";
import { SessionToken } from '@upass/callbox';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private $session = new Subject<string>();
  private token: string | null = null; //cached token
  isFirst = true;

  constructor(private client: HttpClient) { }

  private callAPI() {
    return this.client.post<any>(environment.api_url + "/user/session", {});
  }

  getSession(fresh?: boolean) {
    return new Observable<string>(sub => {
      if(fresh || this.isFirst) {
        this.isFirst = false;
        this.callAPI().subscribe(res => {
          this.token = res.sessionToken;
          sub.next(this.token);
          sub.complete();

          //update session stream
          this.$session.next(this.token);
        })
      } else if (this.token) {
        sub.next(this.token);
        sub.complete();
      } else {
        //case should only occur when token has no value, as startup
        //api call should be in progress
        const newSub = this.$session.subscribe(ses => {
          sub.next(this.token);
          sub.complete();

          //cleanup
          of(true).pipe(delay(50)).subscribe(empy => {
            newSub.unsubscribe();
          })
        })
      }

    })
  }

  getSessionStream() {
    return this.$session.asObservable();
  }

  getToken() {
    return this.getSession().pipe(
      map((val) => this.decode(val))
    );
  }

  decode(token: string) {
    return jwt_decode(token) as SessionToken;
  }

}
