<div class="page" *ngIf="!isLoading">
  <div>
    <div class="d-flex justify-content-center">
      <img src="/assets/img/phone-icon.svg" class="header-img grow-in" />
    </div>
    <p class="text-muted text-center">Where should we forward calls to if a guest doesnt have a pass?</p>
  </div>

  <!--body-->
  <form #form="ngForm" name="callbox" (submit)="clickNext()">
    <div>
      <app-phone-input (isValidPhone)="isValidPhone($event)" name="phone" [(value)]="phone" [showErrors]="isSubmitted"></app-phone-input>
    </div>

    <div class="text-danger text-sm" *ngIf="isSubmitted && form.form.invalid">
      Please fill out all fields
    </div>

  </form>

</div>

<app-setup-step-buttons (next)="clickNext()" (previous)="clickPrevious()" *ngIf="!isLoading"></app-setup-step-buttons>



<div class="page" *ngIf="isLoading">
  <div class="d-flex justify-content-center">
    <app-page-loader></app-page-loader>
  </div>
</div>
