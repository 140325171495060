import { Directive, ElementRef, Input, Host, forwardRef, HostListener, Renderer2, SimpleChanges } from "@angular/core";
import { NgModel, ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import * as moment from "moment";

const DATETYPE_VALUE_ACCESSOR_PROVIDER = [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DateTypeDirective), multi: true }];

/**
 * Converts date string from api, moment dates, & Date objects to date input readable by type="date" or type="datetime-local"
 */
@Directive({
   selector: "[upassDate]",
   providers: [DATETYPE_VALUE_ACCESSOR_PROVIDER],
})
export class DateTypeDirective implements ControlValueAccessor {
   model: Date;
   private viewFormat = "YYYY-MM-DD"; // default view format
   private modelFormat = "YYYY-MM-DDTHH:mm:ssZ";
   private fallbackValue: any = null;
   private onChangeFn: (value: any) => void = () => {};
   private onTouchedFn: () => void = () => {};

   /** Sets custom format to bind to input */
   @Input("dateInputFormat") set format(value: string) {
      this.viewFormat = value;
      this.updateView(this.model, true);
   }

    /** Sets custom format to bind to model defaults to ISO string */
   @Input("dateModelFormat") set inputFormat(value: string) {
      this.modelFormat = value;
      this.updateView(this.model, true);
   }

   @Input("type") set inputType(value: string) {
      if (value === "date") {
         this.viewFormat = "YYYY-MM-DD";
      } else if (value === "datetime-local") {
         this.viewFormat = "YYYY-MM-DDTHH:mm";
      }
   }

   @HostListener("blur")
   onBlur() {
      this.onTouchedFn();
   }

   @HostListener("input", ["$event"])
   onChange(event: any) {
      let viewValue = event.target.value;
      this.updateModel(viewValue);
      this.onChangeFn(this.model);
   }

   constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

   registerOnChange(fn: (value: Date) => void): void {
      this.onChangeFn = fn;
   }

   registerOnTouched(fn: () => void): void {
      this.onTouchedFn = fn;
   }

   writeValue(value: any): void {
      if (value !== undefined) {
         this.model = value;
         this.updateView(this.model, true);
      }
   }

   /**
    * Update model value with new view value
    * 'Informing other form directives and controls when the view/DOM changes'
    */
   updateModel(viewValue: any) {
      let date = moment(viewValue, this.viewFormat).utc();
      this.model = date.isValid() ? date : viewValue;
   }

   /**
    * Update view value with new model value
    * 'Writing a value from the form model into the view/DOM'
    */
   updateView(modelValue: Date, forceUpdate = false) {
      let date = moment(modelValue);

      if (!date.isValid() && !forceUpdate) {
         return;
      }

      let viewValue = date.isValid() ? date.format(this.viewFormat) : "";

      this.renderer.setProperty(this.elementRef.nativeElement, "value", viewValue);
   }
}
