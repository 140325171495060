import { isPossiblePhoneNumber, isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";

/** Parses a phone string into country code and national number */
export function parsePhone(phone: string) {

    const phoneNumber = parsePhoneNumber(phone);
    console.log(phoneNumber);

    return {
      country:  "+" + phoneNumber.countryCallingCode,
      phone: phoneNumber.nationalNumber.toString()
    };

}

/** Returns true if phone number is valid */
export function validatePhone(phone: string) {

  if(!isValidPhoneNumber(phone) || !isPossiblePhoneNumber(phone)) {
    return false;
  }

  return true;
}
