import { Injectable } from '@angular/core';
import { UserPhoneVerificationResponse, UserPhoneVerificationCodeRequest, UserVerifyPhoneCodeRequest, UserNotificationSettings, NotificationSettingsVM, UpdateUserRequest, UpdatePasswordRequest } from "@upass/callbox";
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private api: ApiService) { }


  getPhoneVerificationCode(options: UserPhoneVerificationCodeRequest) {
    return this.api.post<UserPhoneVerificationResponse>("/user/phone/code", options);
  }

  validatePhoneCode(options: UserVerifyPhoneCodeRequest) {
    return this.api.post<any>("/user/phone/validate", options);
  }

  getNotificationOptions() {
    return this.api.get<UserNotificationSettings>("/user/notifications");
  }

  putNotificationOptions(options: NotificationSettingsVM) {
    return this.api.put<UserNotificationSettings>("/user/notifications", options);
  }

  putUser(params: UpdateUserRequest) {
    return this.api.put<any>("/user", params);
  }

  putPassword(params: UpdatePasswordRequest) {
    return this.api.put<any>("/user/password", params);
  }
}
