import { Component, OnInit } from '@angular/core';
import { Location } from "@angular/common";
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CallBoxVM, PutCallBox } from '@upass/callbox';
import { CallboxService } from 'src/app/services/callbox.service';

@Component({
  selector: 'app-callbox-edit',
  templateUrl: './callbox-edit.component.html',
  styleUrls: ['./callbox-edit.component.scss']
})
export class CallboxEditComponent implements OnInit {
  model: PutCallBox;
  callbox: CallBoxVM;
  isSubmittedCallBox = false;
  isSubmittedLocation = false;
  isNext = false;
  isLoading = true;
  validPhone = true;
  constructor(private callboxSvc: CallboxService, private route: ActivatedRoute, private location: Location, private router: Router) { }

  ngOnInit(): void {

    this.route.paramMap.subscribe(param => {
      const callboxId = param.get("callboxId");
      const state = this.location.getState() as CallBoxVM;
      console.log(state);
      if(state.callboxId != null) {
        this.callbox = state;
        this.model = this.callbox as any;
        this.isLoading = false;
        return;
      } else {
        this.callboxSvc.getCallBox(callboxId).subscribe(res => {
          this.callbox = res;
          this.model = res as any;
          this.isLoading = false;
        });
      }

    });
  }

  submitCallbox() {

  }

  clickBack() {
    this.isNext = false;
  }

  clickNext(form: NgForm) {
    this.isSubmittedCallBox = true;

    console.log(form);


    if (!this.validPhone) {
      return;
    }

    if (!form.valid) {
      return;
    }

    this.isNext = true;
  }

  save(form: NgForm) {

    console.log(form);

    if (!this.isSubmittedLocation && !form.valid) {
      this.isSubmittedLocation = true;
      return;
    }

    if (!form.valid) {
      return;
    }

    //save commit changes
    this.isLoading = true;
    this.callboxSvc.putCallBox(this.callbox.callboxId, this.model).subscribe({
      next: res => {
        this.callboxSvc.invalidateCache();
        this.router.navigate(['../'], { relativeTo: this.route });
      },
      error: (err)=> { alert("Error saving callbox") },
      complete: () => { this.isLoading = false; }
    });
  }

  isValidPhone(value: boolean) {
    this.validPhone = value;
  }
}
