import { CallBoxVM, ForwardVM, ProductResponseItem, SessionToken } from '@upass/callbox';
import { Component, OnInit } from '@angular/core';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { SessionService } from 'src/app/services/session.service';
import { CallboxService } from 'src/app/services/callbox.service';
import { ForwardService } from 'src/app/services/forward.service';
import moment from 'moment';

@Component({
  selector: 'app-resubscribe',
  templateUrl: './resubscribe.component.html',
  styleUrls: ['./resubscribe.component.scss']
})
export class ResubscribeComponent implements OnInit {

  plans: ProductResponseItem[];
  callboxes: CallBoxVM[];
  forwards: ForwardVM[];
  selectedPlan: ProductResponseItem = null;
  isLoading = 3;
  step = 0;

  token: SessionToken = null;
  selectedCallboxes: CallBoxVM[] = [];
  selectedForwards: ForwardVM[] = [];
  constructor(private subSvc: SubscriptionService, private sessionSvc: SessionService, private callboxSvc: CallboxService, private forwardSvc: ForwardService) { }

  ngOnInit(): void {
    this.subSvc.listPrices().subscribe(res => {
      this.plans = res;

      this.sessionSvc.getSession(false).subscribe(session => {
        const token = this.sessionSvc.decode(session);
        this.token = token;
        this.isLoading--;
      })
    });

    this.callboxSvc.listCallboxes().subscribe(res => {
      this.callboxes = res;
      this.isLoading--;
    });

    this.forwardSvc.listForwards().subscribe(res => {
      this.forwards = res;
      this.isLoading--;
    })

  }

  onSelectedPlan(plan: ProductResponseItem) {
    this.selectedPlan = plan;
    this.step = 1;
  }

  clickedBackConfirm() {
    this.step = 0;
  }
}
