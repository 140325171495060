import { Injectable } from '@angular/core';
import { PassActivateResponse, PassCreateParams, PassListResponse, PassViewVM, PassVM, PassListQueryParams, CallBoxActivationPingVM, PassUpdateRequest } from '@upass/callbox';
import { AnonApiService } from './api-anon.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class PassService {

  constructor(private api: ApiService, private anonApi: AnonApiService) { }

  createPass(params: PassCreateParams) {
    return this.api.post<PassVM>("/pass", params);
  }

  getPass(shareId: string) {
    return this.api.get<PassViewVM>(`/pass/${shareId}`);
  }

  putPass(shareId: string, body: PassUpdateRequest) {
    return this.api.put<any>(`/pass/${shareId}`, body);
  }

  /** Gets a pass as a guest */
  getGuestPass(shareId: string) {
    return this.anonApi.get<PassViewVM>(`/guest/pass/${shareId}`);
  }

  /** Lists passes associated to current logged in user */
  listPass(params: PassListQueryParams = {}) {
    return this.api.get<PassListResponse>(`/pass`, params);
  }

  /** Returns the share url to share a pass with visitors */
  getShareUrl(pass: PassVM) {
    let url = window.location.href.split("/");
    return url[0] + "//" + url[2] + "/guest-pass?shareId=" + pass.shareId;
  }

  /** Activates a pass to use a callbox */
  activatePass(shareId: string) {
    return this.anonApi.post<PassActivateResponse>('/guest/activate', { shareId: shareId });
  }

  /** Returns activation ping response */
  getActivationPing(activationId: string) {
    return this.anonApi.get<CallBoxActivationPingVM>('/guest/activate/' + activationId);
  }

  /** Returns activation ping response */
  deleteActivation(activationId: string) {
    return this.anonApi.delete('/guest/activate/' + activationId);
  }
}
