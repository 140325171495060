import { ProductResponseItem } from '@upass/callbox';
import { Component, Input, OnInit } from '@angular/core';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'app-resubscribe-payment',
  templateUrl: './resubscribe-payment.component.html',
  styleUrls: ['./resubscribe-payment.component.scss']
})
export class ResubscribePaymentComponent implements OnInit {
  @Input() selectedPlan: ProductResponseItem;
  isLoading = false;

  constructor(private subSvc: SubscriptionService) { }

  ngOnInit(): void {
  }


  redirectToPayment() {
    this.isLoading = true;
      this.subSvc.createResubscribeSession({
        plan: this.selectedPlan.productId
      }).subscribe(res => {
        window.location.href = res.url;
      })

  }
}
