<div>

  <app-page-header title="My Callboxes"></app-page-header>

  <div class="page">
    <div *ngIf="!isLoading">
      <div class="row justify-content-center">
        <div class="col-5">
          <app-progress-circle [value]="currCallboxes" [maxValue]="maxCallboxes" text="Callboxes"></app-progress-circle>
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="flex-grow-1">
          <a class="text-sm" *ngIf="currCallboxes >= maxCallboxes">Upgrade your subscripton to get more callboxes</a>
        </div>
        <button class="btn btn-primary" routerLink="create"  *ngIf="currCallboxes < maxCallboxes">New Callbox</button>
      </div>

      <div *ngIf="items && items.length > 0">
        <div *ngFor="let n of items" [routerLink]="n.callboxId" class="list-row d-flex align-items-center">
          <div class="flex-grow-1">
            <div>{{n.name}} <span class="text-sm">{{n.callboxPhone | phoneFormat}}</span></div>
            <span class="text-muted text-sm">Created {{n.createdAt | amTimeAgo}}</span>
          </div>
          <i class="fa-solid fa-chevron-right px-2"></i>
        </div>
      </div>
      <div *ngIf="!items || items.length == 0">
        <p class="text-muted">You don't have any callboxes setup. Try creating one.</p>
      </div>
    </div>

    <div *ngIf="isLoading">
      <div class="d-flex justify-content-center">
        <app-page-loader></app-page-loader>
      </div>
    </div>

  </div>
</div>
