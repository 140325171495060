<app-page-header title="Resubscribed"></app-page-header>
<div class="page" *ngIf="!isLoading">
  <app-subscription-success [phone]="phone"></app-subscription-success>
  <div class="mt-3">
    <button class="btn btn-block btn-primary" [routerLink]="['/dashboard']" [queryParams]="{ splash: true}">
      Get Started
    </button>
  </div>
</div>

<div class="page" *ngIf="isLoading">
  <app-page-loader></app-page-loader>
</div>

<!--Preload the image-->
<img src="/assets/img/checkbox-icon.svg" class="d-none" />
