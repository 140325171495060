<div class="d-flex justify-content-center">
  <img src="/assets/img/checkbox-icon.svg" class="header-img grow-in" />
</div>
<div class="fade-in">
  <h3 class="text-center">You're all set!</h3>
  <p class="text-center">
    You have been assigned a phone number from upass below. Update your callbox to start using upass.
  </p>
  <ol>
    <li><strong>Update</strong> your callbox to use this phone number</li>
    <li><strong>Create</strong> a upass</li>
    <li><strong>Share</strong> the pass with a friend</li>
    <li>When your guest arrives, uPass will <strong>respond</strong> to the call from the callbox</li>
  </ol>
  <div class="row justify-content-center">
    <div class="col-10">
      <div class="text-center text-sm">Your upass phone number</div>
      <input class="form-control text-center" readonly [value]="phone | phoneFormat"/>
    </div>
  </div>
</div>
