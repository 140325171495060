import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CallboxService } from 'src/app/services/callbox.service';
import { ForwardService } from 'src/app/services/forward.service';
import { UserService } from 'src/app/services/user.service';
import { SetupModel } from '../setup.model';

@Component({
  selector: 'app-setup-phone',
  templateUrl: './setup-phone.component.html',
  styleUrls: ['./setup-phone.component.scss']
})
export class SetupPhoneComponent implements OnInit {
  @Output() next = new EventEmitter<any>();
  @Output() previous = new EventEmitter<any>();
  @Output() commited = new EventEmitter<any>();
  @Input() model: SetupModel;
  isSubmitted = false;
  isLoading = false;
  phone: string = "";
  validPhone: boolean;
  @ViewChild('form', { read: NgForm }) form: any;
  code: string;
  codeSent = false;
  validated = false;
  validationFailed = false;

  constructor(private userSvc: UserService, private forwardSvc: ForwardService) { }

  ngOnInit(): void {
    console.log("INIT")

    this.phone = this.model.userPhone;
    this.validated = this.model.phoneVerified;

    if(this.validated) {
      this.codeSent = true;
    }
  }

  clickSendCode() {
    this.isSubmitted = true;

    if (!this.form.valid) {
      return;
    }

    if(!this.validPhone) {
      return;
    }

    this.sendCode();
  }

  sendCode() {
    this.isLoading = true;
    this.userSvc.getPhoneVerificationCode({
      phone: this.phone
    }).subscribe(res => {
      //save forward.
      this.isLoading = false;
      this.codeSent = true;
    })
  }

  clickValidate() {
    this.isLoading = true;
    this.validationFailed = false;
    this.userSvc.validatePhoneCode({
      code: this.code
    }).subscribe({
      next: res => {
        this.isLoading = false;
        this.validationFailed = false;
        this.validated = true;

        //set model
        this.model.userPhone = this.phone
        this.model.phoneVerified = this.validated;
      },
      error: err => {
        this.isLoading = false;
        this.validationFailed = true;
        this.validated = false;
      }
    })
  }

  clickNext() {
    if(this.validated) {
      this.next.emit();
    }
  }

  changePhone() {
    //reset original fields
    this.phone = "";
    this.codeSent = false;
    this.isSubmitted = false;

    this.model.userPhone = this.phone
    this.model.phoneVerified = this.validated;
  }

  clickPrevious() {
    this.previous.emit();
  }

  skip() {
    this.next.emit();
  }

  isValidPhone(value: boolean) {
    this.validPhone = value;
  }
}
