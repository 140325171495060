import { PassUpdateRequest, PassViewVM } from '@upass/callbox';
import { Component, OnInit } from '@angular/core';
import { PassService } from 'src/app/services/pass.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CallboxService } from 'src/app/services/callbox.service';
import moment from 'moment';

@Component({
  selector: 'app-pass-edit',
  templateUrl: './pass-edit.component.html',
  styleUrls: ['./pass-edit.component.scss']
})
export class PassEditComponent implements OnInit {
  shareId: string;
  pass: PassViewVM;
  isLoading = true;
  startDate: moment.Moment;
  endDate: moment.Moment;
  isDisabled: boolean;
  errors: string[] = [];
  minDate = moment().startOf("day").toISOString().replace("Z", "");

  constructor(private passSvc: PassService, private callboxSvc: CallboxService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
      //get route params
      this.route.paramMap.subscribe(params => {
        this.shareId = params.get("shareId");

        this.passSvc.getPass(this.shareId).subscribe(res => {
          this.pass = res;
          this.isLoading = false;
          this.startDate = moment(res.startDt);
          this.endDate = moment(res.endDt);
          this.isDisabled = res.isDisabled;

          if(this.pass.isExpired) {
            this.errors = ["Pass is expired"];
            return;
          }

          this.callboxSvc.listCallboxes()
        }, err => {
          alert("Pass not found.");
        });
      });
  }


  submit() {
    const now = moment().startOf("day");
    this.errors = [];

    if(this.pass.isExpired) {
      this.errors = ["Pass is expired"];
      return;
    }

    if(this.startDate.isBefore(now) || this.endDate.isBefore(now)) {
      //pass must be in future
      this.errors.push("Pass dates must be in the future");
    }

    if(this.endDate.isBefore(this.startDate)) {
      //enddate must come after start dater
      this.errors.push("End date cannot be before start date");
    }

    if(this.errors.length > 0) {
      return;
    }

    const params = {
      startDt: this.startDate.toISOString(),
      endDt: this.endDate.toISOString(),
      isDisabled: this.isDisabled
    } as PassUpdateRequest;

    //post data
    this.isLoading = true;
    this.passSvc.putPass(this.shareId, params).subscribe(res => {
      this.router.navigate(["../"], { relativeTo: this.route });
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      console.log(err);
    })
  }
}
