


<app-page-header title="Notification Settings"></app-page-header>

<div class="page">

  <div *ngIf="!isLoading">

    <div class="mb-3">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" role="switch" id="enableTxt" [ngModel]="options.enabledSMS" (ngModelChange)="onChange('enabledSMS', $event)">
        <label class="form-check-label" for="enableTxt">Text Notifications</label>
      </div>
      <p class="text-sm text-muted">Receive notifications when events occur like when someone uses a callbox</p>

      <div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="entersCheckTxt" [ngModel]="options.smsOnEntry" [disabled]="!options.enabledSMS" (ngModelChange)="onChange('smsOnEntry', $event)">
          <label class="form-check-label" for="entersCheckTxt">
            When someone enters with your callbox
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="forwardCheckTxt" [(ngModel)]="options.smsOnForward" [disabled]="!options.enabledSMS" (ngModelChange)="onChange('smsOnForward', $event)">
          <label class="form-check-label" for="forwardCheckTxt">
            When we forward a call to you
          </label>
        </div>
      </div>

    </div>

    <!-- <hr /> -->

    <!-- <div>
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" role="switch" id="enableEmail">
        <label class="form-check-label" for="enableEmail">Email Notifications</label>
      </div>
      <p class="text-sm text-muted">Receive notifications when events occur like when someone uses a callbox</p>

      <div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="entersCheckEmail">
          <label class="form-check-label" for="entersCheckEmail">
            When someone enters with your callbox
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="forwardCheckEmail">
          <label class="form-check-label" for="forwardCheckEmail">
            When we forward a call to you
          </label>
        </div>
      </div>

    </div> -->
  </div>

  <div *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>



</div>
