import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { SetupModel } from '../setup.model';

@Component({
  selector: 'app-setup-payment',
  templateUrl: './setup-payment.component.html',
  styleUrls: ['./setup-payment.component.scss'],
})
export class SetupPaymentComponent implements OnInit {
  @Output() next = new EventEmitter<any>();
  @Output() previous = new EventEmitter<any>();
  @Input() model: SetupModel;
  promoCode: string;
  promoId: string = null;
  promoApplied = false;
  promoFailed = false;
  isLoading = false;
  promoLoading = false;

  constructor(
    private fb: FormBuilder,
    private subSvc: SubscriptionService
  ) {}

  ngOnInit(): void {

    if(this.model.productId == null) {
      this.clickPrevious();
    }
  }

  clickPrevious() {
    if(!this.model.isPaid) {
      this.previous.emit();
    }

  }

  redirectToPayment() {
    if(this.model.isPaid) {
      this.next.emit();
      return;
    }

    this.isLoading = true;
    this.subSvc.createSession({
        plan: this.model.productId,
        promo: this.promoApplied? this.promoId : undefined
    }).subscribe(res => {
      window.location.href = res.url;
    })
  }

  applyPromo() {
    this.promoLoading = true;
    this.promoApplied = false;
    this.promoFailed = false;

    //call validate promo
    this.subSvc.validatePromo(this.promoCode, this.model.itemId).subscribe(res => {
      const isValid = res.isValid;
      this.promoApplied = isValid;
      this.promoLoading = false;
      this.promoFailed = !isValid;
      this.promoId = isValid? res.promoId : null;
    })
  }

  removePromo() {
    this.promoApplied = false;
    this.promoCode = null;
    this.promoId = null;
  }
}
