import { PaymentMethodItem } from '@upass/callbox';
import { Component, OnInit } from '@angular/core';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent implements OnInit {
  methods: PaymentMethodItem[] = [];
  isLoading = true;

  constructor(private subSvc: SubscriptionService) { }

  ngOnInit(): void {
    this.subSvc.listPaymentMethods().subscribe(res => {
      this.isLoading = false;

      //put primary on top
      this.methods = res;
      this.sortList();
    })
  }


  clickNewPayment() {
    this.isLoading = true;
    this.subSvc.updatePaymentMethod().subscribe({
      next: res => {
        window.location.href = res.url;
      },
      error: err => {
        this.isLoading = false;
        //TODO: Handle error
      }
    })
  }

  deletePaymentMethod(pmId: string) {
    const prompt = confirm("Are you sure you want to delete this payment method?");
    if(!prompt) {
      return;
    }

    this.isLoading = true;
    this.subSvc.deletePaymentMethod(pmId).subscribe(res => {
      this.isLoading = false;
      this.methods = this.methods.filter(m => m.paymentMethodId != pmId);
    })
  }

  setPreferredPayment(pmId: string) {

    this.isLoading = true;
    this.subSvc.setPreferredPaymentMethod(pmId).subscribe({
      next: res => {
        this.isLoading = false;
        const paymentMethod = this.methods.find(pm => pm.paymentMethodId == pmId);
        const curr = this.methods.find(pm => pm.isPrimary);

        curr.isPrimary = false;
        paymentMethod.isPrimary = true;
      },
      error: err => {
        console.log("Error setting preferred payment method");
      },
      complete: () => {  this.isLoading = false; }
    })
  }

  private sortList() {
    const res = Object.assign(this.methods, []);
    const primary = res.find(m => m.isPrimary);
    const index = res.indexOf(primary);
    res.splice(index, 1);
    this.methods = [primary, ...res];
  }
}
