<app-page-header title="Payment Information"></app-page-header>
<div class="page" *ngIf="!isLoading">
  <div class="d-flex justify-content-center">
    <img src="/assets/img/payment-icon.svg" class="header-img" />
  </div>
  <h3 class="text-center">Payment Info</h3>
  <p class="text-center mb-5">You’ll be redirected to our payment page. You’ll return here when you’re done.</p>
  <div>
    <div class="d-grid gap-2">
      <button class="btn btn-primary" (click)="redirectToPayment()">Take me to checkout</button>
    </div>
  </div>
</div>

<div class="page" *ngIf="isLoading">
  <app-page-loader></app-page-loader>
</div>
