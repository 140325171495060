<div class="page">
  <div *ngIf="!isLoading">
    <div class="d-flex justify-content-center">
      <img src="/assets/img/payment-icon.svg" class="header-img" />
    </div>
    <h3 class="text-center">Payment Info</h3>

    <!--If hasnt paid yet-->
    <div *ngIf="!model.isPaid">
      <p class="text-center mb-5">You’ll be redirected to our payment page. You’ll return here when you’re done.</p>

      <div class="row mb-4 justify-content-center">
        <div class="col-10">
          <div>
            Got a promo code?
            <div class="spinner-border text-primary" role="status" [ngClass]="{ 'invisible': !promoLoading}">
              <span class="sr-only">Loading...</span>
            </div></div>
          <div class="d-flex">
            <input class="form-control" [(ngModel)]="promoCode" [disabled]="promoApplied || promoLoading"/>
            <button class="btn btn-primary btn-sm" *ngIf="!promoApplied" [disabled]="promoLoading" (click)="applyPromo()">Apply</button>
            <button class="btn btn-link" *ngIf="promoApplied && !promoLoading" (click)="removePromo()">Remove</button>
          </div>
          <div class="text-success" *ngIf="promoApplied">
            Promo applied! You will see the discount on the next screen
          </div>
          <div class="text-danger" *ngIf="promoFailed">
            Promo code was not valid
          </div>

        </div>
      </div>


      <div class="">
        <p class="text-muted text-center" *ngIf="model.productId == 0">
          Don’t worry you won’t be charged until the 30 day period has passed. Cancel at any time.
        </p>
        <div class="d-grid gap-2">
          <button class="btn btn-primary" (click)="redirectToPayment()">Take me to checkout</button>
        </div>
      </div>
    </div>

    <!--If already paid-->
    <div *ngIf="model.isPaid">
      <p class="text-center text-success mb-5">Your payment was successful. Thank you for trying out upass!</p>
      <div>
        <p class="text-muted text-center" *ngIf="model.productId == 0">
          Please continue setting up your account.
        </p>
      </div>
    </div>

  </div>
  <div class="d-flex justify-content-center" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>
</div>

<app-setup-step-buttons (next)="redirectToPayment()" (previous)="clickPrevious()" [showPrevious]="!model.isPaid"></app-setup-step-buttons>
