<div class="page" style="padding: 1rem 2rem">
  <div *ngIf="!isLoading">
    <app-subscription-success [phone]="phone"></app-subscription-success>
  </div>
  <div class="d-flex justify-content-center" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>
</div>
<app-setup-step-buttons [showDone]="true" (done)="clickDone()"></app-setup-step-buttons>

<!--Preload the image-->
<img src="/assets/img/checkbox-icon.svg" class="d-none" />
